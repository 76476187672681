import * as icons from '../assets/socialmediaicons/index';

export const basicOptions = {
	title: {
		Validation: {
			required: true,
			minLength: 5,
			maxLength: 30,
		},
	},
	imageUrl: {
		Validation: null,
	},
	userHandle: {
		Validation: null,
	},
	cardHandle: {
		Validation: {
			required: true,
			isUserHandle: true,
			maxLength: 30,
		},
	},
	category: {
		Validation: {
			nullAllowed: true,
		},
	},
	description: {
		Validation: {
			minLength: 5,
			maxLength: 100,
			hashTagLimit: 10,
		},
	},
	QuickConnectApp: {
		Validation: null,
	},
	Visibility: {
		Validation: null,
	},
};

export const socialMediaOptions = {
	//AirBnb: { display: 'AirBnb', Validation: { required: true } },
	Facebook: {
		display: 'Facebook',
		url: 'https://www.facebook.com/[_]',
		target: (value) => `https://www.facebook.com/${value}`,
		icon: icons.facebook,
		tipText: 'Enter the username after facebook.com/',
		Validation: { required: true },
	},
	//HomeAdvisor: { display: 'HomeAdvisor', Validation: { required: true } },
	Instagram: {
		display: 'Instagram',
		url: 'https://www.instagram.com/[_]',
		target: (value) => `https://www.instagram.com/${value}`,
		icon: icons.instagram,
		tipText: 'Enter the username after instagram.com/',
		Validation: { required: true },
	},
	LinkedIn: {
		display: 'LinkedIn',
		url: 'https://www.linkedin.com/in/[_]',
		target: (value) => `https://www.linkedin.com/in/${value}`,
		tipText: 'Enter the username after linkedin.com/in/',
		icon: icons.linkedin,
		Validation: { required: true },
	},
	Pinterest: {
		display: 'Pinterest',
		url: 'https://www.pinterest.com/[_]',
		target: (value) => `https://www.pinterest.com/${value}`,
		tipText: 'Enter the username after pinterest.com/',
		icon: icons.pinterest,
		Validation: { required: true },
	},
	Reddit: {
		display: 'Reddit',
		url: 'https://www.reddit.com/user/[_]',
		target: (value) => `https://www.reddit.com/user/${value}`,
		tipText: 'Enter the username after reddit.com/user/',
		icon: icons.reddit,
		Validation: { required: true },
	},
	Snapchat: {
		display: 'Snapchat',
		url: 'https://www.snapchat.com/add/[_]',
		target: (value) => `https://www.snapchat.com/add/${value}`,
		tipText: 'Enter the username after snapchat.com/add/',
		icon: icons.snapchat,
		Validation: { required: true },
	},
	/* TikTok: {
		display: 'Tik Tok',
		Validation: { required: true },
	}, */
	Tumblr: {
		display: 'Tumblr',
		url: 'https://[_].tumblr.com/',
		target: (value) => `https://${value}.tumblr.com/`,
		tipText: 'Enter the blog name before .tumblr.com',
		icon: icons.tumblr,
		Validation: { required: true },
	},
	Twitter: {
		display: 'Twitter',
		url: 'https://www.twitter.com/[_]',
		target: (value) => `https://www.twitter.com/${value}`,
		tipText: 'Enter the username after twitter.com/',
		icon: icons.twitter,
		Validation: { required: true },
	},
	/* WeddingWire: {
		display: 'Wedding Wire',
		Validation: { required: true },
	}, */
	Whatsapp: {
		display: 'Whatsapp',
		url: 'https://wa.me/[_]',
		target: (value) => `https://www.wa.me/${value}`,
		tipText: 'Enter your phone number using WhatsApp click to chat format',
		icon: icons.whatsapp,
		Validation: { required: true },
	},
	Youtube: {
		display: 'Youtube',
		url: 'https://www.youtube.com/channel/[_]',
		target: (value) => `https://www.youtube.com/channel/${value}`,
		tipText: 'Enter your YouTube channel ID',
		icon: icons.youtube,
		Validation: { required: true },
	},
};
export const generalOptions = {
	website: { display: 'Website', target: (value) => `http://${value}`, Validation: { required: false } },
	email: { display: 'Email', target: (value) => `mailto:${value}`, Validation: { required: false, isEmail: true } },
	phone: { display: 'Phone', target: (value) => `tel:${value}`, Validation: { required: false, isPhoneNumber: true } },
	Address: { display: 'Address', target: (value) => `geo:0,0?q=${value.trim().replace(' ', '+')}`, Validation: { required: false } },
};

export const addressOptions = {
	streetAddress: { display: 'Street Address', Validation: { required: false } },
	city: { display: 'City', Validation: { required: false } },
	//county: { display: 'County', Validation: { required: true } },
	state: { display: 'State/Province/Region', Validation: { required: false } },
	//parish: { display: 'Parish', Validation: { required: true } },
	zip: { display: 'Zip/Postal Code', Validation: { required: false } },
	country: { display: 'Country', Validation: { required: false } },
};

export const linksSettings = {
	display: { /*  tipText: 'Display text', */ Validation: { required: true } },
	value: { /* tipText: 'Target link', */ Validation: { required: true } },
};

export const catergories = [
	{ display: 'Advertising/Marketing', keywords: ['Advertising', 'Marketing'] },
	{ display: 'Album', keywords: ['Album'] },
	{ display: 'Amateur Sports Team', keywords: ['Amateur', 'Sports', 'Team'] },
	{ display: 'Apartment & Condo Building', keywords: ['Apartment', 'Condo', 'Building'] },
	{ display: 'Appliance Repair Service', keywords: ['Appliance', 'Repair', 'Service'] },
	{ display: 'App Page', keywords: ['App', 'Page'] },
	{ display: 'Architectural Designer', keywords: ['Architectural', 'Designer'] },
	{ display: 'Art', keywords: ['Art'] },
	{ display: 'Artist', keywords: ['Artist'] },
	{ display: 'Arts & Entertainment', keywords: ['Arts', 'Entertainment'] },
	{ display: 'Athlete', keywords: ['Athlete'] },
	{ display: 'Automotive Repair Shop', keywords: ['Automotive', 'Repair', 'Shop'] },
	{ display: 'Baby & Children’s Clothing Store', keywords: ['Baby', 'Children’s', 'Clothing', 'Store'] },
	{ display: 'Baby Goods/Kids Goods', keywords: ['Baby', 'Goods', 'Kids', 'Goods'] },
	{ display: 'Bar', keywords: ['Bar'] },
	{ display: 'Beauty, Cosmetic & Personal Care', keywords: ['Beauty', 'Cosmetic', 'Personal', 'Care'] },
	{ display: 'Beauty Salon', keywords: ['Beauty', 'Salon'] },
	{ display: 'Book', keywords: ['Book'] },
	{ display: 'Business Center', keywords: ['Business', 'Center'] },
	{ display: 'Business Service', keywords: ['Business', 'Service'] },
	{ display: 'Camera/Photo', keywords: ['Camera', 'Photo'] },
	{ display: 'Canoe & Kayak Rental', keywords: ['Canoe', 'Kayak', 'Rental'] },
	{ display: 'Chicken Joint', keywords: ['Chicken', 'Joint'] },
	{ display: 'Church of Christ', keywords: ['Church', 'of', 'Christ'] },
	{ display: 'Church of Jesus Christ of Latter-day Saints', keywords: ['Church', 'of', 'Jesus', 'Christ', 'of', 'Latter-day', 'Saints'] },
	{ display: 'Clothing (Brand)', keywords: ['Clothing', '(Brand)'] },
	{ display: 'Clothing Store', keywords: ['Clothing', 'Store'] },
	{ display: 'College & University', keywords: ['College', 'University'] },
	{ display: 'Commercial & Industrial', keywords: ['Commercial', 'Industrial'] },
	{ display: 'Commercial & Industrial Equipment Supplier', keywords: ['Commercial', 'Industrial', 'Equipment', 'Supplier'] },
	{ display: 'Commercial Bank', keywords: ['Commercial', 'Bank'] },
	{ display: 'Commercial Equipment', keywords: ['Commercial', 'Equipment'] },
	{ display: 'Commercial Real Estate Agency', keywords: ['Commercial', 'Real', 'Estate', 'Agency'] },
	{ display: 'Commercial Truck Dealership', keywords: ['Commercial', 'Truck', 'Dealership'] },
	{ display: 'Community', keywords: ['Community'] },
	{ display: 'Community Organization', keywords: ['Community', 'Organization'] },
	{ display: 'Consulting Agency', keywords: ['Consulting', 'Agency'] },
	{ display: 'Contractor', keywords: ['Contractor'] },
	{ display: 'Convenience Store', keywords: ['Convenience', 'Store'] },
	{ display: 'Credit Union', keywords: ['Credit', 'Union'] },
	{ display: 'Doctor', keywords: ['Doctor'] },
	{ display: 'Deli', keywords: ['Deli'] },
	{ display: 'Dancer', keywords: ['Dancer'] },
	{ display: 'Design & Fashion', keywords: ['Design', 'Fashion'] },
	{ display: 'Dessert Shop', keywords: ['Dessert', 'Shop'] },
	{ display: 'Discount Store', keywords: ['Discount', 'Store'] },
	{ display: 'Dorm', keywords: ['Dorm'] },
	{ display: 'E-commerce Website', keywords: ['E-commerce', 'Website'] },
	{ display: 'Education', keywords: ['Education'] },
	{ display: 'Engineering Service', keywords: ['Engineering', 'Service'] },
	{ display: 'Entertainment Website', keywords: ['Entertainment', 'Website'] },
	{ display: 'Entrepreneur', keywords: ['Entrepreneur'] },
	{ display: 'Episode', keywords: ['Episode'] },
	{ display: 'Event', keywords: ['Event'] },
	{ display: 'Family Style Restaurant', keywords: ['Family', 'Style', 'Restaurant'] },
	{ display: 'Fashion Designer', keywords: ['Fashion', 'Designer'] },
	{ display: 'Fashion Model', keywords: ['Fashion', 'Model'] },
	{ display: 'Fast Food Restaurant', keywords: ['Fast', 'Food', 'Restaurant'] },
	{ display: 'Financial Service', keywords: ['Financial', 'Service'] },
	{ display: 'Food & Beverage', keywords: ['Food', 'Beverage'] },
	{ display: 'Food Stand', keywords: ['Food', 'Stand'] },
	{ display: 'Footwear Store', keywords: ['Footwear', 'Store'] },
	{ display: 'Gamer', keywords: ['Gamer'] },
	{ display: 'Games/Toys', keywords: ['Games', 'Toys'] },
	{ display: 'Gaming Video Creator', keywords: ['Gaming', 'Video', 'Creator'] },
	{ display: 'Government Organization', keywords: ['Government', 'Organization'] },
	{ display: 'Graphic Designer', keywords: ['Graphic', 'Designer'] },
	{ display: 'Grocery Store', keywords: ['Grocery', 'Store'] },
	{ display: 'Hardware Store', keywords: ['Hardware', 'Store'] },
	{ display: 'Health/Beauty', keywords: ['Health', 'Beauty'] },
	{ display: 'Heating, Ventilating & Air Conditioning Service', keywords: ['Heating', 'Ventilating', 'Air', 'Conditioning', 'Service'] },
	{ display: 'Home Decor', keywords: ['Home', 'Decor'] },
	{ display: 'Home Improvement', keywords: ['Home', 'Improvement'] },
	{ display: 'Hospital', keywords: ['Hospital'] },
	{ display: 'Hotel', keywords: ['Hotel'] },
	{ display: 'Hotel & Lodging', keywords: ['Hotel', 'Lodging'] },
	{ display: 'Ice Cream Shop', keywords: ['Ice', 'Cream', 'Shop'] },
	{ display: 'In-Home Service', keywords: ['In-Home', 'Service'] },
	{ display: 'Industrial Company', keywords: ['Industrial', 'Company'] },
	{ display: 'Information Technology Company', keywords: ['Information', 'Technology', 'Company'] },
	{ display: 'Insurance Company', keywords: ['Insurance', 'Company'] },
	{ display: 'Interior Design Studio', keywords: ['Interior', 'Design', 'Studio'] },
	{ display: 'Internet Company', keywords: ['Internet', 'Company'] },
	{ display: 'Internet Marketing Service', keywords: ['Internet', 'Marketing', 'Service'] },
	{ display: 'Japanese Restaurant', keywords: ['Japanese', 'Restaurant'] },
	{ display: 'Jazz & Blues Club', keywords: ['Jazz', 'Blues', 'Club'] },
	{ display: 'Jewelry/Watches', keywords: ['Jewelry', 'Watches'] },
	{ display: 'Jewelry & Watches Company', keywords: ['Jewelry', 'Watches', 'Company'] },
	{ display: 'Journalist', keywords: ['Journalist'] },
	{ display: 'Just For Fun', keywords: ['Just', 'For', 'Fun'] },
	{ display: 'Karaoke', keywords: ['Karaoke'] },
	{ display: 'Kennel', keywords: ['Kennel'] },
	{ display: 'Kitchen & Bath Contractor', keywords: ['Kitchen', 'Bath', 'Contractor'] },
	{ display: 'Kitchen/Cooking', keywords: ['Kitchen', 'Cooking'] },
	{ display: 'Korean Restaurant', keywords: ['Korean', 'Restaurant'] },
	{ display: 'Landmark & Historical Place', keywords: ['Landmark', 'Historical', 'Place'] },
	{ display: 'Lawyer & Law Firm', keywords: ['Lawyer', 'Law', 'Firm'] },
	{ display: 'Library', keywords: ['Library'] },
	{ display: 'Loan Service', keywords: ['Loan', 'Service'] },
	{ display: 'Local Service', keywords: ['Local', 'Service'] },
	{ display: 'Lumber Yard', keywords: ['Lumber', 'Yard'] },
	{ display: 'Marketing Agency', keywords: ['Marketing', 'Agency'] },
	{ display: 'Media', keywords: ['Media'] },
	{ display: 'Media/News Company', keywords: ['Media', 'News', 'Company'] },
	{ display: 'Medical Center', keywords: ['Medical', 'Center'] },
	{ display: 'Medical School', keywords: ['Medical', 'School'] },
	{ display: 'Men’s Clothing Store', keywords: ['Men’s', 'Clothing', 'Store'] },
	{ display: 'Mental Health Service', keywords: ['Mental', 'Health', 'Service'] },
	{ display: 'Movie', keywords: ['Movie'] },
	{ display: 'Musician/Band', keywords: ['Musician', 'Band'] },
	{ display: 'Music Lessons & Instruction School', keywords: ['Music', 'Lessons', 'Instruction', 'School'] },
	{ display: 'Music Video', keywords: ['Music', 'Video'] },
	{ display: 'News & Media Website', keywords: ['News', 'Media', 'Website'] },
	{ display: 'Newspaper', keywords: ['Newspaper'] },
	{ display: 'Nonprofit Organization', keywords: ['Nonprofit', 'Organization'] },
	{ display: 'Non-Governmental Organization (NGO)', keywords: ['Non-Governmental', 'Organization', '(NGO)'] },
	{ display: 'Not a Business', keywords: ['Not', 'a', 'Business'] },
	{ display: 'Nursing Agency', keywords: ['Nursing', 'Agency'] },
	{ display: 'Obstetrician-Gynecologist (OBGYN)', keywords: ['Obstetrician', 'Gynecologist', '(OBGYN)'] },
	{ display: 'Office Equipment Store', keywords: ['Office', 'Equipment', 'Store'] },
	{ display: 'Office Supplies', keywords: ['Office', 'Supplies'] },
	{ display: 'Optician', keywords: ['Optician'] },
	{ display: 'Optometrist', keywords: ['Optometrist'] },
	{ display: 'Outdoor & Sporting Goods Company', keywords: ['Outdoor', 'Sporting', 'Goods', 'Company'] },
	{ display: 'Personal Blog', keywords: ['Personal', 'Blog'] },
	{ display: 'Petting Zoo', keywords: ['Petting', 'Zoo'] },
	{ display: 'Photographer', keywords: ['Photographer'] },
	{ display: 'Product/Service', keywords: ['Product', 'Service'] },
	{ display: 'Public & Government Service', keywords: ['Public', 'Government', 'Service'] },
	{ display: 'Public Figure', keywords: ['Public', 'Figure'] },
	{ display: 'Public Utility Company', keywords: ['Public', 'Utility', 'Company'] },
	{ display: 'Real Estate', keywords: ['Real', 'Estate'] },
	{ display: 'Real Estate Agent', keywords: ['Real', 'Estate', 'Agent'] },
	{ display: 'Real Estate Appraiser', keywords: ['Real', 'Estate', 'Appraiser'] },
	{ display: 'Real Estate Company', keywords: ['Real', 'Estate', 'Company'] },
	{ display: 'Real Estate Developer', keywords: ['Real', 'Estate', 'Developer'] },
	{ display: 'Record Label', keywords: ['Record', 'Label'] },
	{ display: 'Religious Center', keywords: ['Religious', 'Center'] },
	{ display: 'Religious Organization', keywords: ['Religious', 'Organization'] },
	{ display: 'Residence', keywords: ['Residence'] },
	{ display: 'Restaurant', keywords: ['Restaurant'] },
	{ display: 'Retail Bank', keywords: ['Retail', 'Bank'] },
	{ display: 'School', keywords: ['School'] },
	{ display: 'Science, Technology & Engineering', keywords: ['Science', 'Technology', 'Engineering'] },
	{ display: 'Shopping & Retail', keywords: ['Shopping', 'Retail'] },
	{ display: 'Shopping District', keywords: ['Shopping', 'District'] },
	{ display: 'Shopping Mall', keywords: ['Shopping', 'Mall'] },
	{ display: 'Smoothie & Juice Bar', keywords: ['Smoothie', 'Juice', 'Bar'] },
	{ display: 'Song', keywords: ['Song'] },
	{ display: 'Specialty School', keywords: ['Specialty', 'School'] },
	{ display: 'Sports & Recreation', keywords: ['Sports', 'Recreation'] },
	{ display: 'Sports League', keywords: ['Sports', 'League'] },
	{ display: 'Sports Team', keywords: ['Sports', 'Team'] },
	{ display: 'Teens & Kids Website', keywords: ['Teens', 'Kids', 'Website'] },
	{ display: 'Telemarketing Service', keywords: ['Telemarketing', 'Service'] },
	{ display: 'Tire Dealer & Repair Shop', keywords: ['Tire', 'Dealer', 'Repair', 'Shop'] },
	{ display: 'Trade School', keywords: ['Trade', 'School'] },
	{ display: 'Traffic School', keywords: ['Traffic', 'School'] },
	{ display: 'Train Station', keywords: ['Train', 'Station'] },
	{ display: 'Tutor/Teacher', keywords: ['Tutor', 'Teacher'] },
	{ display: 'TV Channel', keywords: ['TV', 'Channel'] },
	{ display: 'TV Network', keywords: ['TV', 'Network'] },
	{ display: 'TV Show', keywords: ['TV', 'Show'] },
	{ display: 'Uniform Supplier', keywords: ['Uniform', 'Supplier'] },
	{ display: 'Urban Farm', keywords: ['Urban', 'Farm'] },
	{ display: 'Vacation Home Rental', keywords: ['Vacation', 'Home', 'Rental'] },
	{ display: 'Veterinarian', keywords: ['Veterinarian'] },
	{ display: 'Video Creator', keywords: ['Video', 'Creator'] },
	{ display: 'Video Game', keywords: ['Video', 'Game'] },
	{ display: 'Visual Arts', keywords: ['Visual', 'Arts'] },
	{ display: 'Web Designer', keywords: ['Web', 'Designer'] },
	{ display: 'Website', keywords: ['Website'] },
	{ display: 'Wedding Planning Service', keywords: ['Wedding', 'Planning', 'Service'] },
	{ display: 'Winery/Vineyard', keywords: ['Winery', 'Vineyard'] },
	{ display: 'Women’s Clothing Store', keywords: ['Women’s', 'Clothing', 'Store'] },
	{ display: 'Women’s Health Clinic', keywords: ['Women’s', 'Health', 'Clinic'] },
	{ display: 'Writer', keywords: ['Writer'] },
	{ display: 'Yoga Studio', keywords: ['Yoga', 'Studio'] },
	{ display: 'Zoo', keywords: ['Zoo'] },
];
export const catergoriesT = [
	'Advertising/Marketing',
	'Album',
	'Amateur Sports Team',
	'Apartment & Condo Building',
	'Appliance Repair Service',
	'App Page',
	'Architectural Designer',
	'Art',
	'Artist',
	'Arts & Entertainment',
	'Athlete',
	'Automotive Repair Shop',
	'Baby & Children’s Clothing Store',
	'Baby Goods/Kids Goods',
	'Bar',
	'Beauty, Cosmetic & Personal Care',
	'Beauty Salon',
	'Book',
	'Business Center',
	'Business Service',
	'Camera/Photo',
	'Canoe & Kayak Rental',
	'Chicken Joint',
	'Church of Christ',
	'Church of Jesus Christ of Latter-day Saints',
	'Clothing (Brand)',
	'Clothing Store',
	'College & University',
	'Commercial & Industrial',
	'Commercial & Industrial Equipment Supplier',
	'Commercial Bank',
	'Commercial Equipment',
	'Commercial Real Estate Agency',
	'Commercial Truck Dealership',
	'Community',
	'Community Organization',
	'Consulting Agency',
	'Contractor',
	'Convenience Store',
	'Credit Union',
	'Doctor',
	'Deli',
	'Dancer',
	'Design & Fashion',
	'Dessert Shop',
	'Discount Store',
	'Dorm',
	'E-commerce Website',
	'Education',
	'Engineering Service',
	'Entertainment Website',
	'Entrepreneur',
	'Episode',
	'Event',
	'Family Style Restaurant',
	'Fashion Designer',
	'Fashion Model',
	'Fast Food Restaurant',
	'Financial Service',
	'Food & Beverage',
	'Food Stand',
	'Footwear Store',
	'Gamer',
	'Games/Toys',
	'Gaming Video Creator',
	'Government Organization',
	'Graphic Designer',
	'Grocery Store',
	'Hardware Store',
	'Health/Beauty',
	'Heating, Ventilating & Air Conditioning Service',
	'Home Decor',
	'Home Improvement',
	'Hospital',
	'Hotel',
	'Hotel & Lodging',
	'Ice Cream Shop',
	'In-Home Service',
	'Industrial Company',
	'Information Technology Company',
	'Insurance Company',
	'Interior Design Studio',
	'Internet Company',
	'Internet Marketing Service',
	'Japanese Restaurant',
	'Jazz & Blues Club',
	'Jewelry/Watches',
	'Jewelry & Watches Company',
	'Journalist',
	'Just For Fun',
	'Karaoke',
	'Kennel',
	'Kitchen & Bath Contractor',
	'Kitchen/Cooking',
	'Korean Restaurant',
	'Landmark & Historical Place',
	'Lawyer & Law Firm',
	'Library',
	'Loan Service',
	'Local Service',
	'Lumber Yard',
	'Marketing Agency',
	'Media',
	'Media/News Company',
	'Medical Center',
	'Medical School',
	'Men’s Clothing Store',
	'Mental Health Service',
	'Movie',
	'Musician/Band',
	'Music Lessons & Instruction School',
	'Music Video',
	'News & Media Website',
	'Newspaper',
	'Nonprofit Organization',
	'Non-Governmental Organization (NGO)',
	'Not a Business',
	'Nursing Agency',
	'Obstetrician-Gynecologist (OBGYN)',
	'Office Equipment Store',
	'Office Supplies',
	'Optician',
	'Optometrist',
	'Outdoor & Sporting Goods Company',
	'Personal Blog',
	'Petting Zoo',
	'Photographer',
	'Product/Service',
	'Public & Government Service',
	'Public Figure',
	'Public Utility Company',
	'Real Estate',
	'Real Estate Agent',
	'Real Estate Appraiser',
	'Real Estate Company',
	'Real Estate Developer',
	'Record Label',
	'Religious Center',
	'Religious Organization',
	'Residence',
	'Restaurant',
	'Retail Bank',
	'School',
	'Science, Technology & Engineering',
	'Shopping & Retail',
	'Shopping District',
	'Shopping Mall',
	'Smoothie & Juice Bar',
	'Song',
	'Specialty School',
	'Sports & Recreation',
	'Sports League',
	'Sports Team',
	'Teens & Kids Website',
	'Telemarketing Service',
	'Tire Dealer & Repair Shop',
	'Trade School',
	'Traffic School',
	'Train Station',
	'Tutor/Teacher',
	'TV Channel',
	'TV Network',
	'TV Show',
	'Uniform Supplier',
	'Urban Farm',
	'Vacation Home Rental',
	'Veterinarian',
	'Video Creator',
	'Video Game',
	'Visual Arts',
	'Web Designer',
	'Website',
	'Wedding Planning Service',
	'Winery/Vineyard',
	'Women’s Clothing Store',
	'Women’s Health Clinic',
	'Writer',
	'Yoga Studio',
	'Zoo',
];
