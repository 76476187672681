import * as actionTypes from './actionTypes';
import { reqCollection, resParseGeneral, reqCollectionAdd, reqCollectionRemove } from '../../database/databaseAPI';

const collectionSetCollection = (data) => {
	return {
		type: actionTypes.SET_COLLECTION,
		collection: data,
	};
};

export const collectionGetCollection = () => {
	return (dispatch) => {
		dispatch({ type: actionTypes.GET_COLLECTION });
		reqCollection().then((response) => {
			dispatch(collectionSetCollection(resParseGeneral(response)));
		});
	};
	//TODO: Implement catch for then. Develop strategy to handle all errors.
};

const updateAddToCollection = (data) => {
	return {
		type: actionTypes.ADD_TO_COLLECTION,
		add: { [data.cardID]: data.collectionCard },
		isPublic: data.isPublic,
	};
};

export const collectionAddToCollection = (cardID) => {
	return (dispatch) => {
		reqCollectionAdd({ cardID }).then((response) => {
			dispatch(updateAddToCollection(resParseGeneral(response)));
		});
	};
	//TODO: Implement catch for then. Develop strategy to handle all errors.
};

const updateRemoveFromCollection = (data) => {
	return {
		type: actionTypes.REMOVE_FROM_COLLECTION,
		remove: data.cardID,
	};
};

export const collectionRemoveFromCollection = (cardID, deleted) => {
	return (dispatch) => {
		reqCollectionRemove({ cardID, deleted }).then((response) => {
			dispatch(updateRemoveFromCollection(resParseGeneral(response)));
		});
	};
	//TODO: Implement catch for then. Develop strategy to handle all errors.
};
