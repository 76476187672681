import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconCard from '../Cards/IconCard/IconCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
	Cards: {
		width: '100%',
		display: 'grid',
		[theme.breakpoints.up('xs')]: {
			gridTemplateColumns: 'repeat(3, 33.33%)',
		},
		[theme.breakpoints.up('sm')]: {
			gridTemplateColumns: 'repeat(4, 25%)',
		},
		[theme.breakpoints.up('md')]: {
			gridTemplateColumns: 'repeat(5, 20%)',
		},
		[theme.breakpoints.up('lg')]: {
			gridTemplateColumns: 'repeat(6, 16.66%)',
		},
	},
}));
//TODO: Implement https://www.npmjs.com/package/react-infinite-scroll-component https://www.youtube.com/watch?v=gk_6BKiy6X4

const ExploreCards = (props) => {
	let items = null;
	const classes = useStyles();

	if (props.Cards) {
		items = props.Cards.map((Card, idx) => {
			return <IconCard key={idx} title={Card.title} imageUrl={Card.imageUrl} userHandle={Card.userHandle} cardHandle={Card.cardHandle} />;
		});
	}
	//return <div className={classes.Cards}>{ExploreCardItems}</div>;
	return (
		<InfiniteScroll
			dataLength={items.length} //This is important field to render the next data
			next={props.fetchData}
			hasMore={props.hasMore}
			//loader={<h4>Loading...</h4>}
			//endMessage={
			//	<p style={{ textAlign: 'center' }}>
			//		<b>Yay! You have seen it all</b>
			//	</p>
			//}
			className={classes.Cards}
			// below props only if you need pull down functionality
			//refreshFunction={this.refresh}
			//pullDownToRefresh
			//pullDownToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>}
			//releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>}
		>
			{items}
		</InfiniteScroll>
	);
};

export default ExploreCards;
