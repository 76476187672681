import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import Typography from '@material-ui/core/Typography';
import 'firebase/auth';

import { withStyles } from '@material-ui/core/styles';

const uiConfig = {
	// Popup signin flow rather than redirect flow.
	signInFlow: 'popup',
	// Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
	signInSuccessUrl: '/',
	// We will display Google and Facebook as auth providers.
	//TODO: Add other Auth Providers
	//TODO: Allow for merging of Anonymous sign in.
	//signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID],
	signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};
const styles = (theme) => ({
	root: { textAlign: 'center', margin: '2rem' },
});
class SignUp extends Component {
	state = {
		email: '',
		password: '',
	};
	inputChangedHandler = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};
	render() {
		const { classes } = this.props;
		return this.props.authInfo !== null ? (
			<Redirect to='/' />
		) : (
			<React.Fragment>
				<div className={classes.root}>
					<Typography variant='h5'>Start creating your own cards and collections!</Typography>
				</div>
				<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		authInfo: state.auth.authInfo,
	};
};

export default withStyles(styles)(connect(mapStateToProps)(SignUp));
