import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { LOAD_STATE_SUCCESS } from '../../appcfg/constants';

import LoadingPage from '../../components/UI/LoadingPage/LoadingPage';
import CardHeader from '../../components/Cards/FullCard/FullCardHeader';

import * as actionTypes from '../../store/actions/actionTypes';
import * as actions from '../../store/actions/index';

const styles = (theme) => ({
	root: {
		maxWidth: '30rem',
		margin: 'auto',
	},
	card: {
		margin: theme.spacing(1),
		padding: theme.spacing(1),
	},
});

class FullCard extends Component {
	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.props.authInfo && this.props.collLoadState !== LOAD_STATE_SUCCESS) {
			this.props.onGetCollection();
		}
	}
	handlerRemoveCollection = () => {
		this.props.onRemoveFromCollection(this.props.match.params.card, true);
		//TODO: Redirect once done.
	};

	render() {
		let FullCardElement = <LoadingPage />;
		const { collection, collLoadState, classes } = this.props;
		const cardID = this.props.match.params.card;

		if (collLoadState === LOAD_STATE_SUCCESS) {
			const card = collection[cardID];
			if (card) {
				FullCardElement = (
					<Paper className={classes.card} elevation={3}>
						<CardHeader
							imageUrl={card.imageUrl}
							title={card.title}
							userHandle={card.userHandle}
							cardHandle={card.cardHandle}
							cardID={cardID}
							isDeleted={true}
							remove={this.handlerRemoveCollection}
							collLoadState={collLoadState}
							collDetails={this.props.collection}
							groupNames={this.props.groupNames}
							updateCollection={this.props.onGetCollection}
						/>
					</Paper>
				);
			} else {
				FullCardElement = <Redirect to='/my/collection' />;
			}
		}
		return <div className={classes.root}>{FullCardElement}</div>;
	}
}

const mapStateToProps = (state) => {
	return {
		collLoadState: state.coll.loadState,
		collection: state.coll.collection,
		groupNames: state.coll.groupNames,
		authInfo: state.auth.authInfo,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		setHeader: (headerText) => dispatch({ type: actionTypes.SET_APP_HEADER, headerText }),
		onGetCollection: () => dispatch(actions.collectionGetCollection()),
		onRemoveFromCollection: (cardID) => dispatch(actions.collectionRemoveFromCollection(cardID)),
	};
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FullCard));
