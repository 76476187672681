import * as actionTypes from '../actions/actionTypes';
import { setAuthHeader } from '../../database/databaseAPI';

const initialState = {
	authInfo: null,
	authUserID: null,
	authToken: null,
	isAuthenticated: false,
	authLoaded: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_SET_USER:
			return {
				...state,
				authInfo: action.auth ? action.auth : null,
				authUserID: action.auth ? action.auth.uid : null,
				isAuthenticated: action.auth ? true : false,
				authLoaded: action.auth ? false : true,
			};
		case actionTypes.AUTH_SET_TOKEN:
			setAuthHeader(action.token);
			return {
				...state,
				authToken: action.token,
				authLoaded: true,
			};
		case actionTypes.AUTH_CLEAR:
			setAuthHeader(null);
			return initialState;
		default:
			return state;
	}
};

export default reducer;
