import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CategoryIcon from '@material-ui/icons/Category';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.secondary.dark,
	},
}));

const ExploreChip = (props) => {
	const classes = useStyles();
	let icon = null;
	let value = props.value;
	let type = props.type;
	if (type === 'hashtags') {
		icon = <LocalOfferIcon className={classes.icon} />;
	} else if (type === 'location') {
		icon = <LocationOnIcon className={classes.icon} />;
	} else if (type === 'category') {
		icon = <CategoryIcon className={classes.icon} />;
	}
	return <Chip icon={icon} label={value} color='secondary' onClick={() => props.handleChipClick(value, type)} />;
};

export default ExploreChip;
