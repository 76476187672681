import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import Collapse from '@material-ui/core/Collapse';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import { reqAccessCard, resParseGeneral } from '../../../../database/databaseAPI';

const useStyles = makeStyles((theme) => ({
	textField: {
		width: '100%',
	},
	nested: {
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(10),
	},
}));

const AccessDialog = (props) => {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);
	const { open } = props;
	let Collectors = null;
	let Requesters = null;
	const handleClick = () => {
		setExpanded(!expanded);
	};

	const handleAccess = (requestUserID, action) => {
		reqAccessCard({ cardID: props.cardID, requestUserID, action }).then((response) => {
			const res = resParseGeneral(response);
			props.updateAccess(res.requestUserID, res.action);
		});
	};

	if (props.Access) {
		Collectors = Object.keys(props.Access.collectors).map((id) => {
			let User = props.Access.collectors[id];
			return (
				<ListItem key={id} button component={RouterLink} to={'/userid/' + id}>
					<ListItemAvatar>
						<Avatar alt={User.userName} src={User.userPhotoUrl} />
					</ListItemAvatar>
					<ListItemText primary={User.userName} />
					<ListItemSecondaryAction>
						<IconButton onClick={() => handleAccess(id, 'delete')} edge='end'>
							<DeleteIcon />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			);
		});
		Requesters = Object.keys(props.Access.requested).map((id) => {
			let User = props.Access.requested[id];
			return (
				<ListItem key={id} button component={RouterLink} to={'/userid/' + id} className={classes.nested}>
					<ListItemAvatar>
						<Avatar alt={User.userName} src={User.userPhotoUrl} />
					</ListItemAvatar>
					<ListItemText primary={User.userName} primaryTypographyProps={{ noWrap: true }} />
					<ListItemSecondaryAction>
						<IconButton onClick={() => handleAccess(id, 'deny')} edge='end'>
							<ClearIcon />
						</IconButton>
						<IconButton onClick={() => handleAccess(id, 'accept')} edge='end'>
							<CheckIcon />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			);
		});
	}
	return (
		<Dialog open={open} onClose={props.handleClose} fullWidth={true}>
			<DialogTitle>Collectors</DialogTitle>
			<DialogContent>
				<List component='nav'>
					<ListItem button onClick={handleClick}>
						<ListItemIcon>
							<PersonAddIcon />
						</ListItemIcon>
						<ListItemText primary={`New Requests (${Requesters.length})`} />
						{expanded ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={expanded} timeout='auto' unmountOnExit>
						<List component='nav' disablePadding>
							{Requesters}
						</List>
					</Collapse>
					{Collectors}
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.handleClose} color='primary'>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AccessDialog;
