import * as actionTypes from './actionTypes';
import { updateObject } from '../../bsw/utility';
import { reqCard, resParseGeneral, reqSaveCard } from '../../database/databaseAPI';

const cardSetCard = (data) => {
	return {
		type: actionTypes.SET_CARD,
		card: data,
	};
};
export const cardSavedCard = () => {
	return {
		type: actionTypes.SAVED_CARD,
	};
};

export const cardGetCard = (userHandle, cardHandle) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.GET_CARD });
		reqCard(userHandle, cardHandle)
			.then((response) => {
				dispatch(cardSetCard(resParseGeneral(response)));
			})
			.catch((error) => {
				dispatch({
					type: actionTypes.ERROR_CARD,
				});
			});
	};
	//TODO: Implement catch for then. Develop strategy to handle all errors.
};

export const cardAddCard = (newCard) => {
	return (dispatch) => {
		reqSaveCard(newCard)
			.then((response) => {
				//console.log('Document written with ID: ', response.cardID);
				//dispatch(cardSetCard(extractCard(response)));
			})
			.catch((error) => {
				//console.error('Error adding document: ', error);
			});
	};
	//TODO: Implement catch for then. Develop strategy to handle all errors.
};

export const cardAccessUpdate = (requestUserID, action) => {
	return (dispatch, getState) => {
		const { card } = getState().card;
		let updatedAccess = null;
		let updatedRequested = { ...card.Access.requested };
		let updatedCollectors = { ...card.Access.collectors };

		if (action === 'accepted') {
			updatedCollectors = updateObject(updatedCollectors, { [requestUserID]: updatedRequested[requestUserID] });
			delete updatedRequested[requestUserID];
		} else if (action === 'denied') {
			delete updatedRequested[requestUserID];
		} else if (action === 'deleted') {
			delete updatedCollectors[requestUserID];
		}
		updatedAccess = updateObject(card.Access, { requested: updatedRequested });
		updatedAccess = updateObject(updatedAccess, { collectors: updatedCollectors });
		//console.log(updatedAccess);
		dispatch({ type: actionTypes.ACCESS_UPDATE_CARD, Access: updatedAccess });
	};
	//TODO: Implement catch for then. Develop strategy to handle all errors.
};
