import * as actionTypes from '../actions/actionTypes';

const initialState = {
	user_loading: true,
	coll_loading: true,
	headerText: null,
	Snack: {
		alert: false,
		Action: {
			type: null,
			to: '/',
			call: null,
			text: '',
		},
		open: false,
		severity: 'info',
		message: '',
	},
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USER:
			return {
				...state,
				user_loading: true,
			};
		case actionTypes.SET_USER:
			return {
				...state,
				user_loading: false,
			};
		case actionTypes.COLLECTION_SET_GROUP:
			return {
				...state,
				headerText: action.group,
			};
		case actionTypes.SET_APP_HEADER:
			return {
				...state,
				headerText: action.headerText,
			};
		case actionTypes.SAVED_USER:
			return {
				...state,
				Snack: {
					...state.Snack,
					open: true,
					alert: true,
					severity: 'success',
					message: 'User Saved Succesfully!',
					Action: {
						...initialState.Snack.Action,
					},
				},
			};
		case actionTypes.SAVED_CARD:
			return {
				...state,
				Snack: {
					...state.Snack,
					open: true,
					alert: true,
					severity: 'success',
					message: 'Card Saved Succesfully!',
					Action: {
						...initialState.Snack.Action,
					},
				},
			};
		case actionTypes.REMOVE_FROM_COLLECTION:
			return {
				...state,
				Snack: {
					...state.Snack,
					open: true,
					alert: true,
					severity: 'warning',
					message: 'Card Removed from Collection!',
					Action: {
						...initialState.Snack.Action,
					},
				},
			};
		case actionTypes.ADD_TO_COLLECTION:
			return {
				...state,
				Snack: {
					...state.Snack,
					open: true,
					alert: true,
					severity: 'success',
					message: 'Card Added to Collection!',
					Action: {
						...initialState.Snack.Action,
					},
				},
			};
		case actionTypes.UI_ALERT_CLOSE:
			return {
				...state,
				Snack: {
					...state.Snack,
					open: false,
				},
			};
		case actionTypes.UI_ALERT_CUSTOM:
			return {
				...state,
				Snack: {
					...initialState.Snack,
					...action.Snack,
					Action: {
						...initialState.Snack.Action,
						...action.Snack.Action,
					},
				},
			};
		default:
			return state;
	}
};

export default reducer;
