import * as actionTypes from '../actions/actionTypes';

const initialState = {
	cards: [],
	profHandle: null,
	profID: null,
	loadedProfile: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.PROFILE_SET_PROFILE:
			return {
				...state,
				profHandle: action.profHandle,
				profID: action.profID,
				cards: action.cards,
				loadedProfile: true,
			};
		case actionTypes.PROFILE_CLEAR:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
