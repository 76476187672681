import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
	searchFilters: {
		justifyContent: 'center',
		margin: theme.spacing(1),
	},
	searchFiltersForm: {
		//margin: theme.spacing(1, 0),
		width: '100%',
	},
}));

const SearchType = (props) => {
	const classes = useStyles();

	return (
		<FormControl className={classes.searchFiltersForm} component='fieldset'>
			<RadioGroup className={classes.searchFilters} row value={props.searchType} onChange={props.handleFilterChange}>
				<FormControlLabel value='all' control={<Radio size='small' color='primary' />} label='All' />
				<FormControlLabel value='category' control={<Radio size='small' color='primary' />} label='Category' />
				<FormControlLabel value='hashtags' control={<Radio size='small' color='primary' />} label='Tags' />
				<FormControlLabel style={{ marginRight: 0 }} value='location' control={<Radio size='small' color='primary' />} label='Location' />
			</RadioGroup>
		</FormControl>
	);
};

export default SearchType;
