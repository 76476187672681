import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import thunk from 'redux-thunk';

import authReducer from './store/reducers/authentication';
import userDataReducer from './store/reducers/userInfo';
import profileDataReducer from './store/reducers/profile';
import cardDataReducer from './store/reducers/card';
import collectionDataReducer from './store/reducers/collection';
import uiReducer from './store/reducers/ui';

import themeObject from './appcfg/theme';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme(themeObject);

const rootReducer = combineReducers({
	auth: authReducer,
	user: userDataReducer,
	prof: profileDataReducer,
	card: cardDataReducer,
	coll: collectionDataReducer,
	UI: uiReducer,
});
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

const app = (
	<MuiThemeProvider theme={theme}>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</MuiThemeProvider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
