import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Options from '../FullCard/FullCardHeaderOptions';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	options: {
		position: 'absolute',
		zIndex: '100',
		right: '0',
		background: 'rgba(255, 255, 255, 0.5)',
		margin: theme.spacing(0.5),
	},
	MiniCard: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '0.125rem',
	},
	MiniCardLogo: { width: '100%', overflow: 'hidden' },
	MiniCardLogoImg: {
		width: '100%',
		padding: 0,
		margin: 0,
		display: 'block',
	},
	MiniCardDetails: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		left: 0,
		padding: theme.spacing(1),
		right: 0,
		bottom: 0,
		background: 'rgba(255, 255, 255, 0.8)',
		color: theme.palette.common.black,
		overflow: 'hidden',
	},
}));

const IconCard = (props) => {
	const classes = useStyles();
	let toLink = '/user/' + props.userHandle + '/' + props.cardHandle;
	if (props.deleted) {
		toLink = '/my/collection/del/' + props.cardID;
	}
	return (
		<div className={classes.root}>
			{props.collLoadState ? (
				<Options
					cardID={props.cardID}
					collLoadState={props.collLoadState}
					collDetails={props.collDetails}
					groupNames={props.groupNames}
					updateCollection={props.updateCollection}
					updateAccess={props.updateAccess}
					Access={props.Access}
					isOwner={props.isOwner}
					add={props.add}
					remove={props.remove}
					share={props.share}
					classes={classes}
				/>
			) : null}
			<ButtonBase className={classes.MiniCard} component={RouterLink} to={toLink}>
				<img className={classes.MiniCardLogoImg} alt='' src={props.imageUrl} />

				<div className={classes.MiniCardDetails}>
					<Typography noWrap variant='body2'>
						{props.title}
					</Typography>
				</div>
			</ButtonBase>
		</div>
	);
};

export default IconCard;
