import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const GroupDialog = (props) => {
	const { open, groupNames } = props;
	const [value, setValue] = useState(props.value);
	const [inputValue, setInputValue] = useState('');
	const [edited, setEdited] = useState(false);

	useEffect(() => {
		if (value !== props.value || inputValue !== props.value) {
			setEdited(true);
		} else {
			setEdited(false);
		}
	}, [value, inputValue, props.value]);
	return (
		<Dialog open={open} onClose={props.handleClose} maxWidth='xs' fullWidth={true}>
			<DialogTitle>Group</DialogTitle>
			<DialogContent>
				<Autocomplete
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					options={groupNames}
					freeSolo={true}
					fullWidth={true}
					renderInput={(params) => <TextField {...params} label='Group' variant='outlined' />}
				/>
			</DialogContent>
			<DialogActions>
				{edited ? (
					<>
						<Button onClick={props.handleClose} color='primary'>
							Cancel
						</Button>
						<Button
							onClick={() => {
								props.handleSave({ group: inputValue });
							}}
							color='primary'
						>
							Save
						</Button>
					</>
				) : (
					<Button onClick={props.handleClose} color='primary'>
						Close
					</Button>
				)}
				{props.value !== null ? (
					<Button
						onClick={() => {
							props.handleSave({ group: null });
						}}
						color='primary'
					>
						Ungroup
					</Button>
				) : null}
			</DialogActions>
		</Dialog>
	);
};

export default GroupDialog;
