import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
	},
	space: {
		flexGrow: 1,
	},
	image: {
		minWidth: 128, //TODO: Add break points
		height: 128,
	},
	content: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		marginLeft: theme.spacing(1),
	},
}));

const MiniCard = (props) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardActionArea className={classes.content} component={RouterLink} to={'/user/' + props.userHandle + '/' + props.cardHandle}>
				<CardMedia className={classes.image} image={props.imageUrl} />
				<CardContent className={classes.details}>
					<Typography noWrap variant='h5'>
						{props.title}
					</Typography>
					<Typography noWrap variant='h6'>
						~{props.cardHandle}
					</Typography>

					<Typography noWrap>{props.description}</Typography>
				</CardContent>
			</CardActionArea>

			{props.isCardOwner ? (
				<CardActions>
					<Button startIcon={<DeleteIcon />} onClick={() => props.delete(props.cardID, props.title, props.cardHandle)}>
						Delete
					</Button>
					<div className={classes.space}></div>
					<Button startIcon={<EditIcon />} component={RouterLink} to={'/my/cards/' + props.cardID}>
						Edit
					</Button>
					<Button startIcon={<ShareIcon />} onClick={() => props.share(props.cardHandle, props.title)}>
						Share
					</Button>
				</CardActions>
			) : null}
		</Card>
	);
};

export default MiniCard;
