import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { checkValidity, updateObject } from '../../bsw/utility';
import { reqUserHandleCheck, reqSaveUser, resParseGeneral } from '../../database/databaseAPI';

import * as actions from '../../store/actions/index';
import EditProfilePic from '../../components/Edits/EditPic';

const styles = (theme) => ({
	avatar: {
		width: theme.spacing(12),
		height: theme.spacing(12),
		background: theme.palette.primary.light,
		fontSize: theme.spacing(8),
		margin: 'auto',
	},
	button: {
		position: 'relative',
	},
	buttonProgress: {
		position: 'absolute',
	},
	root: {
		//display: 'flex',
		//flexFlow: 'column',
		padding: theme.spacing(2),
		textAlign: 'center',
		maxWidth: '30rem',
		margin: '0 auto',
	},
	textField: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	saveButton: {
		width: '100%',
	},
});
class EditProfile extends Component {
	state = {
		userHandle: this.props.userHandle ? this.props.userHandle : '',
		displayName: this.props.displayName ? this.props.displayName : '',
		photoURL: this.props.photoURL ? this.props.photoURL : '',
		handleError: '',
		message: null,
		error: false,
		imageEdited: false,
		imageEditActive: false,
		image: null,
		handleEdited: false,
		edited: false,
		saveInProgress: false,
	};

	handlerImageClick = () => {
		this.setState({ imageEditActive: true });
	};
	handlerCancelImage = (image) => {
		this.setState({ imageEditActive: false });
	};
	handlerImageChange = (result) => {
		this.setState({ imageEditActive: false, imageEdited: true, image: result });
	};

	handlerSave = () => {
		if (this.state.handleEdited || this.state.imageEdited || this.state.edited) {
			this.setState({
				saveInProgress: true,
			});
			const { userHandle, image, displayName } = this.state;
			const formData = new FormData();
			if (this.state.imageEdited) {
				formData.append('userImage', image.Blob, image.Blob.name);
			}
			if (this.state.handleEdited) {
				formData.append('userHandle', userHandle);
			}
			if (this.state.edited) {
				formData.append('displayName', displayName);
			}
			this.setState({ imageEditActive: false });

			reqSaveUser(formData).then((response) => {
				const user = resParseGeneral(response);
				let updatedUser = {};
				if (user.displayName) {
					updatedUser = updateObject(updatedUser, { displayName: user.displayName });
				}
				if (user.photoURL) {
					updatedUser = updateObject(updatedUser, { photoURL: user.photoURL });
				}
				if (user.userHandle) {
					updatedUser = updateObject(updatedUser, { userHandle: user.userHandle });
				}
				this.props.onSavedUser(updatedUser);
				this.props.history.push('/userid/' + this.props.authUserID);
			});
		}
	};

	checkHandleAvailable = () => {
		const { userHandle } = this.state;
		this.setState({ message: `'${userHandle.toLowerCase()}' is being checked` });
		reqUserHandleCheck(userHandle).then((res) => {
			if (res.data.available) {
				this.setState({ message: res.data.message, error: false });
			} else {
				this.setState({ message: res.data.message, error: true });
			}
		});
	};

	handlerHandleChange = (event) => {
		let currrentHandle = event.target.value.toLowerCase();
		const result = checkValidity(currrentHandle, { required: true, isUserHandle: true });
		this.setState({
			userHandle: currrentHandle,
			handleEdited: true,
			error: !result.isValid,
			message: result.isValid ? null : result.message,
		});
		clearTimeout(this.handleCheckTimer);
		if (result.isValid && this.props.userHandle !== currrentHandle) {
			this.handleCheckTimer = setTimeout(this.checkHandleAvailable, 1000);
		}
	};

	handlerTextChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			edited: true,
		});
	};

	render() {
		const { classes } = this.props;
		const { userHandle, photoURL, displayName, message, error, image: newImage } = this.state;
		const avatarIcon = newImage ? newImage.Url : photoURL;

		return (
			<div className={classes.root}>
				<Avatar alt={displayName} src={avatarIcon ? avatarIcon : '/'} onClick={this.handlerImageClick} className={classes.avatar} />
				<Button color='primary' onClick={this.handlerImageClick}>
					Change Photo
				</Button>

				<TextField
					className={classes.textField}
					name='displayName'
					type='text'
					label='Display Name'
					placeholder='Choose your user display name'
					value={displayName}
					onChange={this.handlerTextChange}
				/>

				<TextField
					className={classes.textField}
					name='userHandle'
					type='text'
					label='User Handle'
					placeholder='Choose your user handle'
					value={userHandle}
					helperText={message ? message : ''}
					onChange={this.handlerHandleChange}
					error={error}
				/>

				<Button
					className={classes.saveButton}
					variant='outlined'
					color='primary'
					onClick={this.handlerSave}
					disabled={!(this.state.edited || this.state.imageEdited || this.state.handleEdited) || error || this.state.saveInProgress}
				>
					Save
					{this.state.saveInProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
				</Button>
				<EditProfilePic open={this.state.imageEditActive} onComplete={this.handlerImageChange} onCancel={this.handlerCancelImage} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userHandle: state.user.userHandle,
		displayName: state.user.displayName,
		photoURL: state.user.photoURL,
		authUserID: state.auth.authUserID,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onHandleChange: (handle) => dispatch(actions.userInfoSetUserHandle(handle)),
		onSavedUser: (user) => dispatch(actions.userInfoSavedUser(user)),
	};
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditProfile));
