import React from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import { addressOptions } from '../../appcfg/appConstants';

const useStyles = makeStyles((theme) => ({
	textField: {
		width: '100%',
		//marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
}));

const EditAddressDialog = (props) => {
	const classes = useStyles();
	const { open } = props;

	const addressState = props.addressState;

	return (
		<Dialog open={open} onClose={props.handleClose} maxWidth='xs' fullWidth={true}>
			<DialogTitle>Address</DialogTitle>
			<DialogContent>
				<TextField
					label={addressOptions['streetAddress'].display}
					className={classes.textField}
					name={'streetAddress'}
					value={addressState['streetAddress']}
					onChange={props.handlerTextChange}
					error={
						props.errors['streetAddress'] !== '' && props.errors['streetAddress'] !== null && props.errors['streetAddress'] !== undefined
					}
					helperText={
						props.errors['streetAddress'] !== '' && props.errors['streetAddress']
							? props.errors['streetAddress']
							: addressOptions['streetAddress'].tipText
					}
				/>
				<TextField
					label={addressOptions['city'].display}
					className={classes.textField}
					name={'city'}
					value={addressState['city']}
					onChange={props.handlerTextChange}
					error={props.errors['city'] !== '' && props.errors['city'] !== null && props.errors['city'] !== undefined}
					helperText={props.errors['city'] !== '' && props.errors['city'] ? props.errors['city'] : addressOptions['city'].tipText}
				/>
				<TextField
					className={classes.textField}
					label={addressOptions['state'].display}
					name={'state'}
					value={addressState['state']}
					onChange={props.handlerTextChange}
					error={props.errors['state'] !== '' && props.errors['state'] !== null && props.errors['state'] !== undefined}
					helperText={props.errors['state'] !== '' && props.errors['state'] ? props.errors['state'] : addressOptions['state'].tipText}
				/>
				<TextField
					className={classes.textField}
					label={addressOptions['zip'].display}
					name={'zip'}
					value={addressState['zip']}
					onChange={props.handlerTextChange}
					error={props.errors['zip'] !== '' && props.errors['zip'] !== null && props.errors['zip'] !== undefined}
					helperText={props.errors['zip'] !== '' && props.errors['zip'] ? props.errors['zip'] : addressOptions['zip'].tipText}
				/>
				<TextField
					className={classes.textField}
					name={'country'}
					label={addressOptions['country'].display}
					value={addressState['country']}
					onChange={props.handlerTextChange}
					error={props.errors['country'] !== '' && props.errors['country'] !== null && props.errors['country'] !== undefined}
					helperText={
						props.errors['country'] !== '' && props.errors['country'] ? props.errors['country'] : addressOptions['country'].tipText
					}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.handleClose} color='primary'>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditAddressDialog;
