import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import facebook from '../../../assets/socialmediaicons/facebook.png';
import twitter from '../../../assets/socialmediaicons/twitter.png';
import snapchat from '../../../assets/socialmediaicons/snapchat.png';
import reddit from '../../../assets/socialmediaicons/reddit.png';
import instagram from '../../../assets/socialmediaicons/instagram.png';
import whatsapp from '../../../assets/socialmediaicons/whatsapp.png';
import pinterest from '../../../assets/socialmediaicons/pinterest.png';
import linkedin from '../../../assets/socialmediaicons/linkedIn.png';
import youtube from '../../../assets/socialmediaicons/youtube.png';
import tumblr from '../../../assets/socialmediaicons/tumblr.png';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
}));

const IconCard = (props) => {
	const classes = useStyles();
	let socialMediaIcons = {
		Facebook: facebook,
		Twitter: twitter,
		Snapchat: snapchat,
		Reddit: reddit,
		Instagram: instagram,
		Whatsapp: whatsapp,
		Pinterest: pinterest,
		LinkedIn: linkedin,
		Youtube: youtube,
		Tumblr: tumblr,
	};

	return <img className={classes.root} alt='' src={socialMediaIcons[props.icon]} />;
};

export default IconCard;
