import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../bsw/utility';
import { LOAD_STATE_INIT, LOAD_STATE_INPROGRESS, LOAD_STATE_SUCCESS, LOAD_STATE_FAIL } from '../../appcfg/constants';
const initialState = {
	card: null,
	loadState: LOAD_STATE_INIT,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_CARD:
			return {
				...state,
				loadState: LOAD_STATE_INPROGRESS,
			};
		case actionTypes.SET_CARD:
			return {
				...state,
				card: { ...action.card },
				loadState: LOAD_STATE_SUCCESS,
			};
		case actionTypes.ADD_TO_COLLECTION:
			return {
				...state,
				card: updateObject(state.card, action.isPublic ? { inCollection: true } : { isRequested: true }),
			};
		case actionTypes.REMOVE_FROM_COLLECTION:
			return {
				...state,
				card: updateObject(state.card, { inCollection: undefined, isRequested: undefined }),
			};
		case actionTypes.ACCESS_UPDATE_CARD:
			return {
				...state,
				card: updateObject(state.card, { Access: action.Access }),
			};
		case 'actionTypes.ACCESS_UPDATE_CARD':
			let updatedAccess = null;
			let updatedRequested = { ...state.card.Access.requested };
			let updatedCollectors = { ...state.card.Access.collectors };
			if (action.action === 'accept') {
				updatedCollectors = updateObject(updatedCollectors, { [action.requestUserID]: updatedRequested[action.requestUserID] });
				delete updatedRequested[action.requestUserID];
			} else if (action.action === 'deny') {
				delete updatedRequested[action.requestUserID];
			} else if (action.action === 'delete') {
				delete updatedCollectors[action.requestUserID];
			}
			updatedAccess = updateObject(state.card.Access, { requested: updatedRequested });
			updatedAccess = updateObject(updatedAccess, { collectors: updatedCollectors });
			return {
				...state,
				card: updateObject(state.card, { Access: updatedAccess }),
			};
		case actionTypes.ERROR_CARD:
			return {
				...state,
				loadState: LOAD_STATE_FAIL,
			};
		case actionTypes.CLEAR_CARD:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
