import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Badge from '@material-ui/core/Badge';

import GroupDialog from './FullCardDialog/GroupDialog';
import NotesDialog from './FullCardDialog/NotesDialog';
import AccessDialog from './FullCardDialog/AccessDialog';

import { reqCollectionEdit } from '../../../database/databaseAPI';
import { LOAD_STATE_SUCCESS } from '../../../appcfg/constants';

const FullCardHeaderOptions = (props) => {
	const [groupOpen, setGroupOpen] = useState(false);
	const [notesOpen, setNotesOpen] = useState(false);
	const [accessOpen, setAccessOpen] = useState(false);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = Boolean(anchorEl);

	const handleSave = (edit) => {
		const edits = {
			[props.cardID]: edit,
		};
		setGroupOpen(false);
		setNotesOpen(false);
		reqCollectionEdit({ edits }).then((response) => {
			props.updateCollection();
		});
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleOptions = (event) => {
		setAnchorEl(event.currentTarget);
	};
	let options = [];
	let requests = 0;
	if (props.share) {
		options.push(
			<MenuItem
				key='share'
				onClick={() => {
					props.share();
					handleClose();
				}}
			>
				Share
			</MenuItem>
		);
	}
	let collectionDetailsDialog = null;
	if (LOAD_STATE_SUCCESS === props.collLoadState) {
		if (props.collDetails[props.cardID]) {
			options.push(
				<MenuItem
					key='group'
					onClick={() => {
						setGroupOpen(true);
						handleClose();
					}}
				>
					Group
				</MenuItem>,
				<MenuItem
					key='notes'
					onClick={() => {
						setNotesOpen(true);
						handleClose();
					}}
				>
					Notes
				</MenuItem>
			);
			collectionDetailsDialog = (
				<>
					<GroupDialog
						value={props.collDetails[props.cardID].group}
						open={groupOpen}
						handleClose={() => {
							setGroupOpen(false);
						}}
						handleSave={handleSave}
						groupNames={props.groupNames}
					/>
					<NotesDialog
						value={props.collDetails[props.cardID].userNotes}
						open={notesOpen}
						handleSave={handleSave}
						handleClose={() => {
							setNotesOpen(false);
						}}
					/>
				</>
			);
		}
	}

	if (props.isOwner) {
		requests = Object.keys(props.Access.requested).length;
		options.push(
			<MenuItem
				onClick={() => {
					setAccessOpen(true);
					handleClose();
				}}
				key='collectors'
			>
				<Badge badgeContent={requests} color='secondary'>
					Show Collectors
				</Badge>
			</MenuItem> /* ,
			<MenuItem key='blocked'>Show Blocked</MenuItem> */
		);
	}

	return (
		<>
			<IconButton size='small' color='inherit' onClick={handleOptions} className={props.classes ? props.classes.options : null}>
				<Badge badgeContent={requests} color='secondary'>
					<MoreVertIcon />
				</Badge>
			</IconButton>
			<Menu
				id='menu-appbar'
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={open}
				onClose={handleClose}
			>
				{options}
			</Menu>
			{collectionDetailsDialog}
			{props.isOwner ? (
				<AccessDialog
					cardID={props.cardID}
					updateAccess={props.updateAccess}
					open={accessOpen}
					handleSave={handleSave}
					handleClose={() => {
						setAccessOpen(false);
					}}
					Access={props.Access}
				/>
			) : null}
		</>
	);
};

export default FullCardHeaderOptions;
