import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { socialMediaOptions } from '../../../appcfg/appConstants';
import SocialMediaIcons from '../../UI/SocialMediaIcons/SocialMediaIcons';
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '0.5rem 0',
		display: 'flex',
		flexWrap: 'wrap',
	},
	element: {
		width: theme.spacing(8),
		height: theme.spacing(8),
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	elementIcon: {
		width: theme.spacing(5),
	},
}));

const DisplaySocialMedia = (props) => {
	const classes = useStyles();
	let elements = null;

	if (props.details !== null && props.details !== undefined) {
		elements = Object.keys(props.details)
			.sort((el1, el2) => props.details[el1].order - props.details[el2].order)
			.map((id) => {
				let detail = props.details[id];
				return (
					<ButtonBase
						key={id}
						href={socialMediaOptions[id].target(detail.value)}
						target='_blank'
						rel='noopener noreferrer'
						className={classes.element}
					>
						<div className={classes.elementIcon}>
							<SocialMediaIcons icon={id} />
						</div>
						<Typography variant='caption'>{socialMediaOptions[id].display}</Typography>
					</ButtonBase>
				);
			});
	}
	return <div className={classes.root}>{elements}</div>;
};

export default DisplaySocialMedia;
