import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { generalOptions, addressOptions } from '../../../appcfg/appConstants';
import ButtonBase from '@material-ui/core/ButtonBase';
const useStyles = makeStyles((theme) => ({
	detail: {
		display: 'flex',
		justifyContent: 'start',
	},
	caption: {
		fontWeight: '500',
		width: '5rem',
		flexShrink: 0,
	},
	value: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

const DisplayGeneral = (props) => {
	const classes = useStyles();
	let element = null;

	if (props.details !== null && props.details !== undefined) {
		element = Object.keys(generalOptions).map((id) => {
			if (props.details[id]) {
				let value = '';
				let detail = props.details[id];
				if (id === 'Address') {
					const addressKeys = Object.keys(addressOptions);
					addressKeys.forEach((id) => {
						if (detail[id]) {
							value = value + detail[id] + ' ';
						}
					});
				} else {
					value = detail;
				}
				return value ? (
					<ButtonBase key={id} href={generalOptions[id].target(value)} target='_blank' rel='noopener noreferrer' className={classes.detail}>
						<Typography className={classes.caption}>{generalOptions[id].display}</Typography>
						<Typography className={classes.value} href={generalOptions[id].target(value)}>
							{value}
						</Typography>
					</ButtonBase>
				) : null;
			} else {
				return null;
			}
		});
	}
	return element;
};

export default DisplayGeneral;
