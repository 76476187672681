import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';

import { ReactComponent as Logo } from '../../../assets/logo/Logo-Final-Magnes-plain.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	avatar: {
		width: theme.spacing(5),
		height: theme.spacing(5),
		background: theme.palette.primary.light,
		fontSize: theme.spacing(8),
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	text: {
		textOverflow: 'ellipsis',
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	LogoLink: {
		flexGrow: 1,
		height: theme.spacing(4),
		textAlign: 'center',
	},
	Logo: {
		fill: theme.palette.secondary.contrastText,
		height: '100%',
		width: 'auto',
	},
}));

const Header = (props) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const { displayName, photoURL } = props;

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const logoutHandler = () => {
		props.logout();
		handleClose();
	};
	let HeaderContents = (
		<RouterLink className={classes.LogoLink} to='/'>
			<Logo className={classes.Logo} />
		</RouterLink>
	);
	if (props.headerText != null) {
		HeaderContents = (
			<Typography className={classes.text} noWrap variant='h6'>
				{props.headerText}
			</Typography>
		);
	}
	return (
		<AppBar position='static' color='secondary'>
			<Toolbar>
				<IconButton className={classes.backButton} color='inherit' onClick={props.backbutton}>
					<ArrowBackIcon />
				</IconButton>
				{HeaderContents}
				{/* <IconButton color='inherit'>
					<SettingsOutlinedIcon />
				</IconButton> */}
				<Avatar onClick={handleMenu} alt={displayName} src={photoURL ? photoURL : '/'} className={classes.avatar} component={ButtonBase} />
				<Menu
					id='menu-appbar'
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={open}
					onClose={handleClose}
				>
					{props.authUserID ? (
						[
							<MenuItem key='viewProfile' onClick={handleClose} component={RouterLink} to={'/userid/' + props.authUserID}>
								View Profile
							</MenuItem>,
							<MenuItem key='profile' onClick={handleClose} component={RouterLink} to='/my/profile'>
								Edit Profile
							</MenuItem>,

							<MenuItem key='logout' onClick={logoutHandler}>
								Log Out
							</MenuItem>,
						]
					) : (
						<MenuItem onClick={handleClose} component={RouterLink} to='/login'>
							Log In
						</MenuItem>
					)}
				</Menu>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
