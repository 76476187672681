import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { linksSettings } from '../../appcfg/appConstants';
const useStyles = makeStyles((theme) => ({
	typography: {
		marginRight: theme.spacing(1),
		width: '4rem',
	},
	displaytextfields: {
		flexGrow: 1,
	},
	linktextfields: {
		width: '100%',
	},
	element: {
		marginBottom: theme.spacing(2),
	},
	subelement: {
		display: 'flex',
		alignItems: 'baseline',
		marginBottom: theme.spacing(1),
	},
	elementaction: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
}));

const EditLinks = (props) => {
	const classes = useStyles();

	const linksState = props.linksState;

	let linksUI = [];
	if (linksState.length > 0) {
		linksState.forEach((link, index) => {
			linksUI.push(
				<div key={index} className={classes.element}>
					<div className={classes.subelement}>
						<TextField
							className={classes.displaytextfields}
							label='Display Text'
							value={link.display}
							onChange={(event) => props.handlerTextChange(event, 'display', index)}
							error={props.errors[index] !== undefined && props.errors[index].display !== null && props.errors[index].display !== ''}
							helperText={
								props.errors[index] !== undefined && props.errors[index].display !== '' && props.errors[index].display
									? props.errors[index].display
									: linksSettings.display.tipText
							}
						/>
						<IconButton size='small' className={classes.elementaction} onClick={() => props.deleteField(index)}>
							<DeleteIcon />
						</IconButton>
						<IconButton
							size='small'
							className={classes.elementaction}
							onClick={() => props.orderChange(index, true)}
							disabled={index === 0}
						>
							<UpIcon />
						</IconButton>
						<IconButton
							size='small'
							className={classes.elementaction}
							onClick={() => props.orderChange(index, false)}
							disabled={index === linksState.length - 1}
						>
							<DownIcon />
						</IconButton>
					</div>
					<TextField
						className={classes.linktextfields}
						label='Target URL'
						value={link.value}
						onChange={(event) => props.handlerTextChange(event, 'value', index)}
						error={props.errors[index] !== undefined && props.errors[index].value !== '' && props.errors[index].value !== null}
						helperText={
							props.errors[index] !== undefined && props.errors[index].value !== '' && props.errors[index].value
								? props.errors[index].value
								: linksSettings.value.tipText
						}
					/>
				</div>
			);
		});
	}

	if (true) {
		linksUI.push(
			<div key={linksState.length} className={classes.element}>
				<div className={classes.subelement}>
					<TextField
						label='Display Text'
						className={classes.displaytextfields}
						value=''
						onChange={(event) => props.handlerTextChange(event, 'add', -1)}
						helperText={linksSettings.display.tipText}
					/>
				</div>
				<TextField
					label='Target URL'
					value=''
					className={classes.linktextfields}
					InputProps={{
						readOnly: true,
					}}
					helperText={linksSettings.value.tipText}
				/>
			</div>
		);
	}

	return linksUI;
};
export default EditLinks;
