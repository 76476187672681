import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actionTypes from '../../store/actions/actionTypes';
import Header from '../../components/Navigation/Header/Header';
import Footer from '../../components/Navigation/Footer/Footer';
import Snacks from '../../components/UI/Snacks';
import Connect from '../../components/Connect/Connect';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
	content: { height: '100%', marginBottom: 70 },
});

class Layout extends Component {
	state = {
		connecting: false,
		signining: false,
	};
	connectHandler = () => {
		this.setState({ connecting: true });
	};
	connectEndHandler = () => {
		this.setState({ connecting: false });
	};
	signinHandler = () => {
		this.setState({ signining: true });
	};
	signinEndHandler = () => {
		this.setState({ signining: false });
	};
	manageHandler = () => {
		alert('To Do: Allow users to manage collections and groups');
	};
	optionsHandler = () => {
		alert('To Do: Edit/Delete if your card, Control Visibility of your card, Share card, Add to/Move in/Remove from collection, Report Card');
	};
	logoutHandler = () => {
		this.props.onClearAuth();
		this.props.onClearUser();
		this.props.logoutAction();
	};

	backbutton = () => {
		window.history.back();
	};
	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<Header
					backbutton={this.backbutton}
					logout={this.logoutHandler}
					optionsAction={this.optionsHandler}
					manageAction={this.manageHandler}
					settingsAction={this.signinHandler}
					headerText={this.props.headerText}
					authUserID={this.props.authUserID}
					photoURL={this.props.photoURL}
					displayName={this.props.displayName}
				/>

				<main className={classes.content}>{this.props.children}</main>

				<Footer connectAction={this.connectHandler} authUserID={this.props.authUserID} />
				<Connect show={this.state.connecting} modalClosed={this.connectEndHandler} />

				<Snacks Snack={this.props.Snack} onSnackClose={this.props.onSnackClose} />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authUserID: state.auth.authUserID,
		headerText: state.UI.headerText,
		Snack: state.UI.Snack,
		displayName: state.user.displayName,
		photoURL: state.user.photoURL,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onClearAuth: () => dispatch({ type: actionTypes.AUTH_CLEAR }),
		onClearUser: () => dispatch({ type: actionTypes.USERINFO_CLEAR }),
		onSnackClose: () => dispatch({ type: actionTypes.UI_ALERT_CLOSE }),
	};
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Layout));
