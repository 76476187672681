export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_CLEAR = 'AUTH_CLEAR';
export const AUTH_DONE = 'AUTH_DONE';

export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';
export const SET_USER_HANDLE = 'SET_USER_HANDLE';
export const SAVED_USER = 'SAVED_USER';

export const SAVE_NEW_SEARCH = 'SAVE_NEW_SEARCH';

export const USERINFO_SET_CARDS = 'USERINFO_SET_CARDS';
export const USERINFO_SET_RECOMMENDATIONS = 'USERINFO_SET_RECOMMENDATIONS';
export const USERINFO_CLEAR = 'USERINFO_CLEAR';

export const USERINFO_SET_USERHANDLE_REQ_STATUS = 'USERINFO_SET_USERHANDLE_REQ_STATUS';

export const PROFILE_SET_PROFILE = 'PROFILE_SET_PROFILE';
export const PROFILE_CLEAR = 'PROFILE_CLEAR';

export const SET_CARD = 'SET_CARD';
export const GET_CARD = 'GET_CARD';
export const SAVED_CARD = 'SAVED_CARD';
export const CLEAR_CARD = 'CLEAR_CARD';
export const ERROR_CARD = 'ERROR_CARD';
export const ACCESS_UPDATE_CARD = 'ACCESS_UPDATE_CARD';

export const SET_COLLECTION = 'SET_COLLECTION';
export const GET_COLLECTION = 'GET_COLLECTION';
export const ERROR_COLLECTION = 'ERROR_COLLECTION';
export const ADD_TO_COLLECTION = 'ADD_TO_COLLECTION';
export const REMOVE_FROM_COLLECTION = 'REMOVE_FROM_COLLECTION';
export const COLLECTION_SET_GROUP = 'COLLECTION_SET_GROUP';

export const SET_COLLECTION_GROUP = 'SET_COLLECTION_GROUP';

export const SET_APP_HEADER = 'SET_APP_HEADER';
export const UI_ALERT_CLOSE = 'UI_ALERT_CLOSE';
export const UI_ALERT_CUSTOM = 'UI_ALERT_CUSTOM';
