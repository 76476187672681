import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { LOAD_STATE_SUCCESS, LOAD_STATE_FAIL } from '../../appcfg/constants';

import LoadingPage from '../../components/UI/LoadingPage/LoadingPage';
import BrokenLink from '../../components/UI/BrokenLink';
import CardHeader from '../../components/Cards/FullCard/FullCardHeader';
import CardCDLinks from '../../components/Cards/FullCard/FullCardCDLinks';
import CardCDGeneral from '../../components/Cards/FullCard/FullCardCDGeneral';
import CardCDSocialMedia from '../../components/Cards/FullCard/FullCardCDSocialMedia';
import { MagnesShare } from '../../bsw/utility';

import * as actionTypes from '../../store/actions/actionTypes';
import * as actions from '../../store/actions/index';

const styles = (theme) => ({
	root: {
		maxWidth: '30rem',
		margin: 'auto',
	},
	card: {
		margin: theme.spacing(1),
		padding: theme.spacing(1),
	},
	locked: {
		width: theme.spacing(10),
		height: theme.spacing(10),
	},
	lockedFrame: {
		textAlign: 'center',
	},
});

class FullCard extends Component {
	componentDidMount() {
		this.props.onGetCard(this.props.match.params.user, this.props.match.params.card);
		window.scrollTo(0, 0);
	}
	componentWillUnmount() {
		this.props.onClearCard();
	}
	handlerAddtoCollection = () => {
		if (this.props.authInfo) {
			this.props.onAddToCollection(this.props.card.cardID, false);
		} else {
			this.props.onSnackChanged({
				open: true,
				severity: 'error',
				message: 'Please sign in to use collection',
				alert: true,
				Action: {
					type: 'link',
					to: '/login',
					text: 'Log In',
				},
			});
		}
	};
	handlerRemoveCollection = () => {
		this.props.onRemoveFromCollection(this.props.card.cardID, false);
	};
	handlerShare = () => {
		const { title, userHandle, cardHandle } = this.props.card;
		MagnesShare(title, userHandle, cardHandle);
	};

	render() {
		let FullCardElement = <LoadingPage />;
		//let card = { ...this.state };
		const card = this.props.card;
		const { classes, loadState, collLoadState } = this.props;

		if (LOAD_STATE_SUCCESS === loadState) {
			if (LOAD_STATE_SUCCESS !== collLoadState && (card.inCollection || card.isRequested)) {
				this.props.onGetCollection();
			}
			if (card.cardID !== null) {
				let FullCardElementCD = null;
				if (card.ContactDetails) {
					FullCardElementCD = (
						<>
							<CardCDGeneral details={card.ContactDetails.General} />
							<CardCDSocialMedia details={card.ContactDetails.SocialMedia} />
							<CardCDLinks details={card.ContactDetails.Links} />
						</>
					);
				} else if (!card.isPublic && !(card.inCollection || card.isOwner)) {
					FullCardElementCD = (
						<div className={classes.lockedFrame}>
							<LockOutlinedIcon color='secondary' className={classes.locked} />
						</div>
					);
				}
				FullCardElement = (
					<Paper className={classes.card} elevation={3}>
						<CardHeader
							imageUrl={card.imageUrl}
							title={card.title}
							userHandle={card.userHandle}
							cardHandle={card.cardHandle}
							cardID={card.cardID}
							category={card.category}
							description={card.description}
							inCollection={card.inCollection}
							isRequested={card.isRequested}
							isOwner={card.isOwner}
							Access={card.Access}
							add={this.handlerAddtoCollection}
							remove={this.handlerRemoveCollection}
							share={this.handlerShare}
							collLoadState={collLoadState}
							collDetails={this.props.collection}
							groupNames={this.props.groupNames}
							updateCollection={this.props.onGetCollection}
							updateAccess={this.props.onAccessUpdated}
						/>
						{FullCardElementCD}
					</Paper>
				);
			} else {
				FullCardElement = <BrokenLink />;
			}
		} else if (LOAD_STATE_FAIL === loadState) {
			FullCardElement = <BrokenLink />;
		}
		return <div className={classes.root}>{FullCardElement}</div>;
	}
}

const mapStateToProps = (state) => {
	return {
		authInfo: state.auth.authInfo,
		card: state.card.card,
		loadState: state.card.loadState,
		collLoadState: state.coll.loadState,
		collection: state.coll.collection,
		groupNames: state.coll.groupNames,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onGetCard: (user, card) => dispatch(actions.cardGetCard(user, card)),
		onAccessUpdated: (requestUserID, action) => dispatch(actions.cardAccessUpdate(requestUserID, action)),
		onClearCard: () => dispatch({ type: actionTypes.CLEAR_CARD }),
		setHeader: (headerText) => dispatch({ type: actionTypes.SET_APP_HEADER, headerText }),
		onGetCollection: () => dispatch(actions.collectionGetCollection()),
		onAddToCollection: (cardID) => dispatch(actions.collectionAddToCollection(cardID)),
		onRemoveFromCollection: (cardID) => dispatch(actions.collectionRemoveFromCollection(cardID)),
		onSnackChanged: (Snack) => dispatch({ type: actionTypes.UI_ALERT_CUSTOM, Snack }),
	};
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FullCard));
