import * as actionTypes from './actionTypes';

export const authSetUser = (auth) => {
	return {
		type: actionTypes.AUTH_SET_USER,
		auth: auth
	};
};
export const authSetToken = (token) => {
	return {
		type: actionTypes.AUTH_SET_TOKEN,
		token: token
	};
};
