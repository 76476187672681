import firebase from 'firebase/app';
import 'firebase/firestore';
import firebaseConfig from '../../appcfg/firebaseConfig';

var db = null;
export const initializeFirebase = () => {
	firebase.initializeApp(firebaseConfig);
	//db = firebase.firestore();
};

export const firebaseDoc = (collection, doc) => {
	return db
		.collection(collection)
		.doc(doc)
		.get();
};

export const firebaseDocNested = (collection, doc) => {
	var i;
	var call = db;
	for (i = 0; i < collection.length; i++) {
		call = call.collection(collection[i]).doc(doc[i]);
	}
	return call.get();
};

export const firebaseQuery = (collection, field, operation, value) => {
	return db
		.collection(collection)
		.where(field, operation, value)
		.get();
};

export const firebaseQueryNested = (collection, field, operation, value) => {
	var i;
	var call = db.collection(collection);
	for (i = 0; i < field.length; i++) {
		call = call.where(field[i], operation[i], value[i]);
	}
	return call.get();
};

export const firebaseGetThenLog = (collection, doc) => {
	db.collection(collection)
		.doc(doc)
		.get()
		.then((snapshot) => {
			console.log(snapshot.data());
		});
};

export const firebaseExtract = (data) => {
	return data.data();
};

export default db;
