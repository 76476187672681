import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
		position: 'fixed',
		top: '50%',
		left: '50%',
		/* bring your own prefixes */
		transform: 'translate(-50%, -50%)',
	},
	link: {
		color: theme.palette.primary.main,
	},
}));

const BrokenLink = (props) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Typography variant='h5'>Oops! This page isn't avaialble.</Typography>
			<Typography variant='body1'>The link appears to be broken or the page removed.</Typography>
			<Typography variant='subtitle2'>
				<RouterLink className={classes.link} to='/'>
					Go to Explore
				</RouterLink>
			</Typography>
		</div>
	);
};

export default BrokenLink;
