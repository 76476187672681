import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';
import LoadingPage from '../../components/UI/LoadingPage/LoadingPage';
import { Link as RouterLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { updateObject, updateArray, checkValidity } from '../../bsw/utility';
import { reqSaveCard, resParseGeneral, reqCardByID, reqSaveCardImage } from '../../database/databaseAPI';

import EditSocialMedia from '../../components/Edits/EditSocialMedia';
import EditGeneral from '../../components/Edits/EditGeneral';
import EditLinks from '../../components/Edits/EditLinks';
//import EditQuickConnect from '../../components/Edits/EditQuickConnect';
import EditBasic from '../../components/Edits/EditBasic';
import EditCardPic from '../../components/Edits/EditPic';

import { basicOptions, socialMediaOptions, generalOptions, addressOptions, linksSettings } from '../../appcfg/appConstants';
import * as actions from '../../store/actions/index';
import * as actionTypes from '../../store/actions/actionTypes';

const styles = (theme) => ({
	root: {
		maxWidth: '50rem',
		margin: '0 auto',
	},
	form: {
		display: 'flex',
		flexFlow: 'column',
		padding: theme.spacing(2),
		'& > *': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	panel: {
		display: 'flex',
		flexFlow: 'column',
	},
	button: {
		position: 'relative',
	},
	buttonProgress: {
		position: 'absolute',
	},
	selects: {
		marginRight: theme.spacing(1),
		width: '25%',
	},
	textfields: {
		width: '50%',
	},
	error: { color: theme.palette.error.main },
	noHandle: {
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		color: theme.palette.primary.contrastText,
		background: theme.palette.secondary.light,
	},
	reserveLink: {
		color: theme.palette.primary.contrastText,
		background: theme.palette.secondary.light,
	},
});

class EditCard extends Component {
	state = {
		loading: true,
		profileChanged: false,
		saveInProgress: false,
		edited: false,
		imageEdited: false,
		image: null,
		cardID: null,
		imageEditActive: false,
		Card: {
			title: '',
			userHandle: '',
			cardHandle: '',
			category: null,
			description: '',
			imageUrl: '',
			ContactDetails: {
				General: { Address: { streetAddress: '', city: '', state: '', zip: '', country: '' }, website: '', email: '', phone: '' },
				SocialMedia: {},
				Links: [],
			},
			QuickConnectApp: null,
			Visibility: {
				isPublic: false,
				inExplore: false,
				inProfile: false,
			},
		},
		Errors: {
			title: '',
			userHandle: '',
			cardHandle: '',
			category: '',
			description: '',
			ContactDetails: {
				General: { Address: { AddressEmpty: false } },
				SocialMedia: {},
				Links: [],
			},
		},
	};
	componentDidMount() {
		if (this.props.match.params.cardID) {
			reqCardByID(this.props.match.params.cardID).then((response) => {
				const card = resParseGeneral(response);
				let ErrLinks = [];
				for (let index = 0; index < card.ContactDetails.Links.length; index++) {
					ErrLinks.push({ display: '', value: '' });
				}
				this.setState({
					Card: {
						title: card.title,
						userHandle: card.userHandle,
						cardHandle: card.cardHandle,
						category: card.category,
						description: card.description,
						imageUrl: card.imageUrl,
						ContactDetails: {
							General: card.ContactDetails.General,
							SocialMedia: card.ContactDetails.SocialMedia,
							Links: card.ContactDetails.Links,
						},
						QuickConnectApp: card.QuickConnectApp,
						Visibility: {
							isPublic: card.Visibility.isPublic,
							inExplore: card.Visibility.inExplore,
							inProfile: card.Visibility.inProfile,
						},
					},
					cardID: card.cardID,
					edited: false,
					loading: false,
					Errors: {
						title: '',
						userHandle: '',
						cardHandle: '',
						category: '',
						description: '',
						ContactDetails: {
							General: { Address: { AddressEmpty: false } },
							SocialMedia: {},
							Links: [...ErrLinks],
						},
					},
				});
			});
		} else {
			this.setState({ edited: false, loading: false });
		}
	}
	componentWillUnmount() {
		if (this.state.profileChanged) {
			this.props.onCardsEdited();
		}
	}
	handlerSaveCard = () => {
		this.setState({
			saveInProgress: true,
		});
		if (this.validateAll()) {
			if (this.state.edited) {
				this.setState({
					profileChanged: true,
				});
				const updatedCard = {
					cardID: this.state.cardID,
					...this.state.Card,
				};
				reqSaveCard(updatedCard).then((response) => {
					const card = resParseGeneral(response);
					if (!this.state.cardID && this.state.imageEdited) {
						this.saveImage(card.cardID);
					}
					if (this.state.imageEdited) {
						this.setState({
							edited: false,
						});
					} else {
						this.props.onSavedCard();
						this.props.history.push(`/user/${card.userHandle}/${card.cardHandle}`);
					}
				});
			}
			if (this.state.cardID && this.state.imageEdited) {
				this.setState({
					profileChanged: true,
				});
				this.saveImage(this.state.cardID);
			}
		} else {
			this.setState({
				saveInProgress: false,
			});
			this.props.onSnackChanged({
				open: true,
				alert: true,
				severity: 'error',
				message: 'Please check card for errors',
			});
		}
	};
	saveImage = (cardID) => {
		if (cardID) {
			const image = this.state.image.Blob;
			const formData = new FormData();
			this.setState({ imageEditActive: false });
			formData.append('cardImage', image, image.name);
			formData.append('cardID', cardID);
			reqSaveCardImage(formData).then(() => {
				if (this.state.edited) {
					this.setState({
						imageEdited: false,
						profileChanged: true,
					});
				} else {
					this.props.onSavedCard();
					this.props.history.push(`/user/${this.state.Card.userHandle}/${this.state.Card.cardHandle}`);
				}
			});
		}
	};
	//TODO: refactor to move all functions to separate file
	validateAll = () => {
		const key = 0;
		const value = 1;
		const { Card, Errors } = this.state;
		let isValid = true;
		let result = null;
		const CardObjs = Object.entries(Card);
		let updatedErrors = { ...Errors };
		let updatedErrorsConDet = { ...Errors.ContactDetails };
		let updatedErrorsGen = { ...Errors.ContactDetails.General };
		let updatedErrorsGenAdd = { ...Errors.ContactDetails.General.Address };
		let updatedErrorsSM = { ...Errors.ContactDetails.SocialMedia };
		let updatedErrorsLinks = { ...Errors.ContactDetails.Links };
		CardObjs.forEach((CardObj) => {
			if (CardObj[key] === 'ContactDetails') {
				const CardConDetObjs = Object.entries(CardObj[value]);
				CardConDetObjs.forEach((CardConDetObj) => {
					if (CardConDetObj[key] === 'General' && CardConDetObj[value]) {
						const CardGeneralObjs = Object.entries(CardConDetObj[value]);
						CardGeneralObjs.forEach((CardGeneralObj) => {
							if (CardGeneralObj[key] === 'Address' && CardGeneralObj[value] !== undefined) {
								let isAddressEmpty = true;
								if (CardGeneralObj[value] !== null) {
									const CardGeneralAddObjs = Object.entries(CardGeneralObj[value]);
									CardGeneralAddObjs.forEach((CardGeneralAddObj) => {
										result = checkValidity(CardGeneralAddObj[value], addressOptions[CardGeneralAddObj[key]].Validation);
										updatedErrorsGenAdd[CardGeneralAddObj[key]] = result.message;
										isValid = isValid && result.isValid;
										isAddressEmpty = false;
									});
								}
								isValid = isValid && !isAddressEmpty;
								updatedErrorsGenAdd.AddressEmpty = isAddressEmpty;
								updatedErrorsGen = updateObject(updatedErrorsGen, {
									Address: updatedErrorsGenAdd,
								});
							} else {
								result = checkValidity(CardGeneralObj[value], generalOptions[CardGeneralObj[key]].Validation);
								updatedErrorsGen[CardGeneralObj[key]] = result.message;
								isValid = isValid && result.isValid;
							}
						});
						updatedErrorsConDet = updateObject(updatedErrorsConDet, {
							General: updatedErrorsGen,
						});
						updatedErrors = updateObject(updatedErrors, {
							ContactDetails: updatedErrorsConDet,
						});
					} else if (CardConDetObj[key] === 'SocialMedia' && CardConDetObj[value]) {
						const CardSocialMediaObjs = Object.entries(CardConDetObj[value]);
						CardSocialMediaObjs.forEach((CardSocialMediaObj) => {
							result = checkValidity(CardSocialMediaObj[value].value, socialMediaOptions[CardSocialMediaObj[key]].Validation);
							updatedErrorsSM[CardSocialMediaObj[key]] = result.message;
							isValid = isValid && result.isValid;
						});
						updatedErrorsConDet = updateObject(updatedErrorsConDet, {
							SocialMedia: updatedErrorsSM,
						});
						updatedErrors = updateObject(updatedErrors, {
							ContactDetails: updatedErrorsConDet,
						});
					} else if (CardConDetObj[key] === 'Links' && CardConDetObj[value]) {
						CardConDetObj[value].forEach((CardLinksObj, index) => {
							result = checkValidity(CardLinksObj.display, linksSettings.display.Validation);
							updatedErrorsLinks[index].display = result.message;
							isValid = isValid && result.isValid;
							result = checkValidity(CardLinksObj.value, linksSettings.value.Validation);
							updatedErrorsLinks[index].value = result.message;
							isValid = isValid && result.isValid;
						});
					}
				});
			} else {
				result = checkValidity(CardObj[value], basicOptions[CardObj[key]].Validation);
				updatedErrors[CardObj[key]] = result.message;
				isValid = isValid && result.isValid;
			}
		});
		this.updateError(updatedErrors);
		return isValid;
	};
	validateCard = (name, value, source, index = -1) => {
		const { Errors } = this.state;
		if (source === 'basic' && basicOptions[name].Validation) {
			let updatedErrors = { ...Errors };
			updatedErrors[name] = checkValidity(value, basicOptions[name].Validation).message;
			this.updateError(updatedErrors);
		} else if (source === 'SM' && socialMediaOptions[name].Validation) {
			let updatedErrorsSocialMedia = { ...Errors.ContactDetails.SocialMedia };
			updatedErrorsSocialMedia[name] = checkValidity(value, socialMediaOptions[name].Validation).message;
			this.updateErrorSocialMedia(updatedErrorsSocialMedia);
		} else if (source === 'Gen' && generalOptions[name].Validation) {
			let updatedErrorsGeneral = { ...Errors.ContactDetails.General };
			updatedErrorsGeneral[name] = checkValidity(value, generalOptions[name].Validation).message;
			this.updateErrorGeneral(updatedErrorsGeneral);
		} else if (source === 'GenAdd' && addressOptions[name].Validation) {
			let updatedErrorsGeneralAdd = { ...Errors.ContactDetails.SocialMedia.Address };
			updatedErrorsGeneralAdd[name] = checkValidity(value, addressOptions[name].Validation).message;
			this.updateErrorGeneralAdd(updatedErrorsGeneralAdd);
		} else if (source === 'Link' && linksSettings.display.Validation) {
			let updateLink = null;
			let updatedErrorsLinks = [...Errors.ContactDetails.Links];
			if (name === 'add' && linksSettings.display.Validation) {
				updateLink = {
					display: checkValidity(value, linksSettings.display.Validation).message,
					value: '',
				};
			} else if (name === 'display' && linksSettings.display.Validation) {
				updateLink = {
					display: checkValidity(value, linksSettings.display.Validation).message,
				};
				updateLink = updateObject(updatedErrorsLinks[index], updateLink);
			} else if (name === 'value' && linksSettings.value.Validation) {
				updateLink = {
					value: checkValidity(value, linksSettings.value.Validation).message,
				};
				updateLink = updateObject(updatedErrorsLinks[index], updateLink);
			}
			updatedErrorsLinks = updateArray(updatedErrorsLinks, updateLink, index);
			this.updateErrorLinks(updatedErrorsLinks);
		}
	};
	updateErrorGeneral = (updatedGeneral) => {
		const { ContactDetails } = this.state.Errors;
		const updatedContactDetails = updateObject(ContactDetails, {
			General: updatedGeneral,
		});
		this.updateErrorContactDetails(updatedContactDetails);
	};
	updateErrorGeneralAdd = (updatedGeneralAdd) => {
		const { General } = this.state.Errors.ContactDetails;
		const updatedGeneral = updateObject(General, {
			Address: updatedGeneralAdd,
		});
		this.updateErrorGeneral(updatedGeneral);
	};
	updateErrorSocialMedia = (updatedSocialMedia) => {
		const { ContactDetails } = this.state.Errors;
		const updatedContactDetails = updateObject(ContactDetails, {
			SocialMedia: updatedSocialMedia,
		});
		this.updateErrorContactDetails(updatedContactDetails);
	};
	updateErrorLinks = (updatedLinks) => {
		const { ContactDetails } = this.state.Errors;
		const updatedContactDetails = updateObject(ContactDetails, {
			Links: updatedLinks,
		});
		this.updateErrorContactDetails(updatedContactDetails);
	};
	updateErrorContactDetails = (updatedContactDetails) => {
		const { Errors } = this.state;
		const updatedErrors = updateObject(Errors, {
			ContactDetails: updatedContactDetails,
		});
		this.updateError(updatedErrors);
	};
	updateError = (updatedErrors) => {
		this.setState({ Errors: updatedErrors });
	};

	handlerTextChange = (event) => {
		const { Card } = this.state;
		let value = event.target.value;
		if (event.target.name === 'cardHandle' || event.target.name === 'email') {
			value = value.toLowerCase();
		}
		this.validateCard(event.target.name, value, 'basic');
		const updatedCard = updateObject(Card, { [event.target.name]: value });
		this.updateCard(updatedCard);
	};
	handlerCategoryChange = (event, newValue) => {
		const { Card } = this.state;
		const updatedCard = updateObject(Card, { category: newValue });
		this.updateCard(updatedCard);
	};
	handlerQCASelect = (event) => {
		const { Card } = this.state;
		let updatedQCA = null;
		if (event.target.value !== '') {
			const QCA = event.target.value.split('_');
			if (QCA[0] === 'Links') {
				updatedQCA = { type: QCA[0], value: parseInt(QCA[1]) };
			} else {
				updatedQCA = { type: QCA[0], value: QCA[1] };
			}
		}

		const updatedCard = updateObject(Card, { QuickConnectApp: updatedQCA });
		this.updateCard(updatedCard);
	};

	handlerSMSelect = (event) => {
		const { SocialMedia } = this.state.Card.ContactDetails;
		let position = 0;
		if (SocialMedia) {
			position = Object.keys(SocialMedia).length;
		} else {
			position = 0;
		}
		const updatedSocialMedia = updateObject(SocialMedia, {
			[event.target.value]: { value: '', order: position },
		});
		this.updateSocialMedia(updatedSocialMedia);
	};
	handlerSMTextChange = (event) => {
		const { SocialMedia } = this.state.Card.ContactDetails;
		this.validateCard(event.target.name, event.target.value, 'SM');
		const updatedSocialMedia = updateObject(SocialMedia, {
			[event.target.name]: { value: event.target.value, order: SocialMedia[event.target.name].order },
		});
		this.updateSocialMedia(updatedSocialMedia);
	};
	handlerSMOrderChange = (currentPosition, moveUp) => {
		const { SocialMedia } = this.state.Card.ContactDetails;
		const socialMediaList = Object.entries(SocialMedia);
		let newPostion;
		if (moveUp) {
			newPostion = currentPosition - 1;
		} else {
			newPostion = currentPosition + 1;
		}
		if (newPostion >= 0 && newPostion < socialMediaList.length) {
			let updatedSocialMedia = SocialMedia;
			socialMediaList.forEach((entry) => {
				if (entry[1].order === currentPosition) {
					updatedSocialMedia = updateObject(updatedSocialMedia, {
						[entry[0]]: { value: entry[1].value, order: newPostion },
					});
				} else if (entry[1].order === newPostion) {
					updatedSocialMedia = updateObject(updatedSocialMedia, {
						[entry[0]]: { value: entry[1].value, order: currentPosition },
					});
				}
			});
			this.updateSocialMedia(updatedSocialMedia);
		}
	};
	handlerSMDelete = (id) => {
		const { SocialMedia } = this.state.Card.ContactDetails;
		let updatedSocialMedia = { ...SocialMedia };
		const socialMediaList = Object.keys(updatedSocialMedia);
		socialMediaList.forEach((SM) => {
			if (updatedSocialMedia[SM].order > updatedSocialMedia[id].order) {
				updatedSocialMedia[SM] = updateObject(updatedSocialMedia[SM], {
					order: updatedSocialMedia[SM].order - 1,
				});
			}
		});
		delete updatedSocialMedia[id];
		this.updateSocialMedia(updatedSocialMedia);

		const { SocialMedia: SocialMediaError } = this.state.Errors.ContactDetails;
		let updatedSocialMediaError = { ...SocialMediaError };
		delete updatedSocialMediaError[id];
		this.updateErrorSocialMedia(updatedSocialMediaError);

		this.deleteQCA('SocialMedia', id);
	};
	handlerGenSelect = (event) => {
		const { General } = this.state.Card.ContactDetails;
		const updatedGeneral = updateObject(General, {
			[event.target.value]: '',
		});
		this.updateGeneral(updatedGeneral);
	};
	handlerGenAdd = (id) => {
		const { General } = this.state.Card.ContactDetails;
		const updatedGeneral = updateObject(General, {
			[id]: '',
		});
		this.updateGeneral(updatedGeneral);
	};
	handlerGenTextChange = (event) => {
		const { General } = this.state.Card.ContactDetails;
		this.validateCard(event.target.name, event.target.value, 'Gen');
		const updatedGeneral = updateObject(General, {
			[event.target.name]: event.target.value,
		});
		this.updateGeneral(updatedGeneral);
	};
	handlerGenDelete = (id) => {
		const { General } = this.state.Card.ContactDetails;
		let updatedGeneral = { ...General };
		delete updatedGeneral[id];
		this.updateGeneral(updatedGeneral);

		const { General: GeneralError } = this.state.Errors.ContactDetails;
		let updatedGeneralErrors = { ...GeneralError };
		delete updatedGeneralErrors[id];
		this.updateErrorGeneral(updatedGeneralErrors);

		this.deleteQCA('General', id);
	};
	handlerGenAddSelect = (event) => {
		const { Address } = this.state.Card.ContactDetails.General;
		const updatedAddGeneral = updateObject(Address, {
			[event.target.value]: '',
		});
		this.updateGeneralAdd(updatedAddGeneral);
	};
	handlerGenAddTextChange = (event) => {
		const { Address } = this.state.Card.ContactDetails.General;
		this.validateCard(event.target.name, event.target.value, 'GenAdd');
		const updatedAddGeneral = updateObject(Address, {
			[event.target.name]: event.target.value,
		});
		this.updateGeneralAdd(updatedAddGeneral);
	};

	handlerGenAddDelete = (id) => {
		const { Address } = this.state.Card.ContactDetails.General;
		let updatedAddGeneral = { ...Address };
		delete updatedAddGeneral[id];
		this.updateGeneralAdd(updatedAddGeneral);

		const { Address: AddressErrors } = this.state.Errors.ContactDetails.General;
		let updatedAddGeneralErrors = { ...AddressErrors };
		delete updatedAddGeneralErrors[id];
		this.updateErrorGeneralAdd(updatedAddGeneralErrors);
	};
	handlerLinkTextChange = (event, action, index) => {
		const { Links } = this.state.Card.ContactDetails;
		this.validateCard(action, event.target.value, 'Link', index);
		let newValue = null;
		let updateLink = null;
		if (action === 'display') {
			newValue = {
				display: event.target.value,
			};
			updateLink = updateObject(Links[index], newValue);
		} else if (action === 'value') {
			newValue = {
				value: event.target.value,
			};
			updateLink = updateObject(Links[index], newValue);
		} else if (action === 'add') {
			newValue = {
				display: event.target.value,
				value: '',
			};
			updateLink = newValue;
		}
		const updatedLinks = updateArray(Links, updateLink, index);
		this.updateLinks(updatedLinks);
	};

	handlerLinkOrderChange = (currentPosition, moveUp) => {
		const { Links } = this.state.Card.ContactDetails;
		let updatedLinks = [...Links];
		let temp = null;
		let newPostion;
		if (moveUp) {
			newPostion = currentPosition - 1;
		} else {
			newPostion = currentPosition + 1;
		}

		if (newPostion > -1 && newPostion < updatedLinks.length) {
			temp = updatedLinks[currentPosition];
			updatedLinks[currentPosition] = updatedLinks[newPostion];
			updatedLinks[newPostion] = temp;
			this.updateLinks(updatedLinks);
		}
	};
	handlerLinkDelete = (id) => {
		const { Links } = this.state.Card.ContactDetails;
		let updatedLinks = [...Links];
		updatedLinks.splice(id, 1);
		this.updateLinks(updatedLinks);

		const { Links: LinksErrors } = this.state.Errors.ContactDetails;
		let updatedLinksErrors = [...LinksErrors];
		updatedLinksErrors.splice(id, 1);
		this.updateErrorLinks(updatedLinksErrors);

		this.deleteQCA('Links', id);
	};

	handleSwitchChange = (event) => {
		const updatedVisibility = updateObject(this.state.Card.Visibility, {
			[event.target.name]: event.target.checked,
		});
		const { Card } = this.state;
		const updatedCard = updateObject(Card, { Visibility: updatedVisibility });
		this.updateCard(updatedCard);
	};
	handlerImageChange = (result) => {
		this.setState({ imageEditActive: false, imageEdited: true, image: result });
	};
	handlerImageClick = () => {
		this.setState({ imageEditActive: true });
	};
	handlerCancelImage = (image) => {
		this.setState({ imageEditActive: false });
	};
	deleteQCA = (type, value) => {
		if (this.state.Card.QuickConnectApp) {
			if (type === this.state.Card.QuickConnectApp.type && value === this.state.Card.QuickConnectApp.value) {
				this.setState((prevState) => {
					const { Card } = prevState;
					const updatedCard = updateObject(Card, { QuickConnectApp: null });
					return { Card: updatedCard };
				});
			} else if (type === 'Links' && 'Links' === this.state.Card.QuickConnectApp.type) {
				if (value < this.state.Card.QuickConnectApp.value) {
					this.setState((prevState) => {
						const { Card } = prevState;
						const { QuickConnectApp } = Card;
						const updatedQCA = updateObject(QuickConnectApp, { value: QuickConnectApp.value - 1 });
						const updatedCard = updateObject(Card, { QuickConnectApp: updatedQCA });
						return { Card: updatedCard };
					});
				}
			}
		}
	};

	updateGeneral = (updatedGeneral) => {
		const { ContactDetails } = this.state.Card;
		const updatedContactDetails = updateObject(ContactDetails, {
			General: updatedGeneral,
		});
		this.updateContactDetails(updatedContactDetails);
	};
	updateGeneralAdd = (updatedGeneralAdd) => {
		const { General } = this.state.Card.ContactDetails;
		const updatedGeneral = updateObject(General, {
			Address: updatedGeneralAdd,
		});
		this.updateGeneral(updatedGeneral);
	};

	updateSocialMedia = (updatedSocialMedia) => {
		const { ContactDetails } = this.state.Card;
		const updatedContactDetails = updateObject(ContactDetails, {
			SocialMedia: updatedSocialMedia,
		});
		this.updateContactDetails(updatedContactDetails);
	};
	updateLinks = (updatedLinks) => {
		const { ContactDetails } = this.state.Card;
		const updatedContactDetails = updateObject(ContactDetails, {
			Links: updatedLinks,
		});
		this.updateContactDetails(updatedContactDetails);
	};
	updateContactDetails = (updatedContactDetails) => {
		const { Card } = this.state;
		const updatedCard = updateObject(Card, {
			ContactDetails: updatedContactDetails,
		});
		this.updateCard(updatedCard);
	};
	updateCard = (updatedCard) => {
		this.setState({ Card: updatedCard, edited: true });
	};
	render() {
		const { classes } = this.props;
		const { SocialMedia, General, Links } = this.state.Card.ContactDetails;
		const { SocialMedia: eSocialMedia, General: eGeneral, Links: eLinks } = this.state.Errors.ContactDetails;
		const { title, cardHandle, category, description, imageUrl /* , QuickConnectApp */ } = this.state.Card;
		const { title: etitle, cardHandle: ecardHandle, category: ecategory, description: edescription } = this.state.Errors;
		const { isPublic, inExplore, inProfile } = this.state.Card.Visibility;
		let generalError = null;
		let socialMediaError = null;
		let linksError = null;
		for (const prop in eGeneral) {
			if (prop !== 'Address') {
				if (eGeneral[prop] !== '' && eGeneral[prop] !== null && eGeneral[prop] !== undefined) {
					generalError = classes.error;
				}
			}
		}
		for (const prop in eSocialMedia) {
			if (eSocialMedia[prop] !== '' && eSocialMedia[prop] !== null && eSocialMedia[prop] !== undefined) {
				socialMediaError = classes.error;
			}
		}

		for (let index = 0; index < eLinks.length; index++) {
			if (
				(eLinks[index] !== undefined && eLinks[index].display !== null && eLinks[index].display !== '') ||
				(eLinks[index] !== undefined && eLinks[index].value !== '' && eLinks[index].value !== null)
			) {
				linksError = classes.error;
			}
		}
		return (
			<div className={classes.root}>
				{this.props.userHandle ? (
					!this.state.loading ? (
						<Fragment>
							<form className={classes.form} noValidate autoComplete='off'>
								<EditBasic
									classes={classes}
									values={{ title, cardHandle, category, description, imageUrl }}
									errors={{ etitle, ecardHandle, ecategory, edescription }}
									textChange={this.handlerTextChange}
									categoryChange={this.handlerCategoryChange}
									imageClick={this.handlerImageClick}
									imageEdited={this.state.imageEdited}
									newImage={this.state.image}
								/>

								<Typography variant='h6'>Contact Details</Typography>
								<ExpansionPanel>
									<ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={generalError} />}>
										<Typography variant='subtitle1' className={generalError}>
											General
										</Typography>
									</ExpansionPanelSummary>
									<ExpansionPanelDetails className={classes.panel}>
										<EditGeneral
											handlerSelect={this.handlerGenSelect}
											handlerAdd={this.handlerGenAdd}
											handlerTextChange={this.handlerGenTextChange}
											deleteField={this.handlerGenDelete}
											generalState={General}
											classes={classes}
											handlerAddSelect={this.handlerGenAddSelect}
											handlerAddTextChange={this.handlerGenAddTextChange}
											errors={eGeneral}
											deleteAddField={this.handlerGenAddDelete}
										/>
									</ExpansionPanelDetails>
								</ExpansionPanel>
								<ExpansionPanel>
									<ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={socialMediaError} />}>
										<Typography variant='subtitle1' className={socialMediaError}>
											Social Media
										</Typography>
									</ExpansionPanelSummary>
									<ExpansionPanelDetails className={classes.panel}>
										<EditSocialMedia
											handlerSelect={this.handlerSMSelect}
											handlerTextChange={this.handlerSMTextChange}
											orderChange={this.handlerSMOrderChange}
											deleteField={this.handlerSMDelete}
											socialMediaState={SocialMedia}
											errors={eSocialMedia}
											classes={classes}
										/>
									</ExpansionPanelDetails>
								</ExpansionPanel>
								<ExpansionPanel>
									<ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={linksError} />}>
										<Typography variant='subtitle1' className={linksError}>
											Links
										</Typography>
									</ExpansionPanelSummary>
									<ExpansionPanelDetails className={classes.panel}>
										<EditLinks
											handlerTextChange={this.handlerLinkTextChange}
											orderChange={this.handlerLinkOrderChange}
											deleteField={this.handlerLinkDelete}
											linksState={Links}
											errors={eLinks}
											classes={classes}
										/>
									</ExpansionPanelDetails>
								</ExpansionPanel>
								{/* <EditQuickConnect
							handlerSelect={this.handlerQCASelect}
							generalState={General}
							socialMediaState={SocialMedia}
							linksState={Links}
							QCAValue={QuickConnectApp}
							classes={classes}
						/> */}
								<Typography variant='h6'>Visibility</Typography>
								<FormControlLabel
									control={<Switch checked={isPublic} onChange={this.handleSwitchChange} name='isPublic' color='primary' />}
									label='Card Details are Public'
								/>
								<FormControlLabel
									control={<Switch checked={inExplore} onChange={this.handleSwitchChange} name='inExplore' color='primary' />}
									label='Card Discoverable in Explore'
								/>
								<FormControlLabel
									control={<Switch checked={inProfile} onChange={this.handleSwitchChange} name='inProfile' color='primary' />}
									label='Card Visible in Public Profile'
								/>

								<Button
									variant='outlined'
									color='primary'
									onClick={this.handlerSaveCard}
									disabled={!(this.state.edited || this.state.imageEdited) || this.state.saveInProgress}
									className={classes.button}
								>
									Save
									{this.state.saveInProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
								</Button>
								<EditCardPic
									open={this.state.imageEditActive}
									onComplete={this.handlerImageChange}
									onCancel={this.handlerCancelImage}
								/>
							</form>
						</Fragment>
					) : (
						<LoadingPage />
					)
				) : (
					<Paper className={classes.noHandle}>
						<Typography variant='subtitle1'>
							Please{' '}
							<RouterLink className={classes.reserveLink} to='/my/profile'>
								reserve a User Handle
							</RouterLink>{' '}
							before creating your own cards.
						</Typography>
					</Paper>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		card: state.card,
		userHandle: state.user.userHandle,
		authID: state.auth.authUserID,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onSavedCard: () => dispatch(actions.cardSavedCard()),
		onCardsEdited: () => dispatch(actions.userInfoGetUser(true)),
		onSnackChanged: (Snack) => dispatch({ type: actionTypes.UI_ALERT_CUSTOM, Snack }),
	};
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditCard));
