import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		margin: theme.spacing(0, 0, 1, 0),
	},
	button: {
		flexGrow: 1,
		flexBasis: 0,
	},
	space: {
		width: theme.spacing(1),
	},
}));

const FullCardActions = (props) => {
	const classes = useStyles();
	let CTATitle = (
		<Button variant='outlined' className={classes.button} onClick={props.add} color='primary'>
			Add to Collection
		</Button>
	);
	if (props.inCollection) {
		CTATitle = (
			<>
				<Button variant='outlined' onClick={props.remove} className={classes.button}>
					Remove
				</Button>
				<div className={classes.space}></div>
				<Button variant='outlined' color='primary' className={classes.button} onClick={props.share}>
					Share
				</Button>
			</>
		);
	}
	if (props.isRequested) {
		CTATitle = (
			<Button variant='outlined' className={classes.button} onClick={props.remove} color='primary'>
				Cancel Request
			</Button>
		);
	}
	if (props.isDeleted) {
		CTATitle = (
			<Button variant='outlined' className={classes.button} onClick={props.remove} color='primary'>
				Remove
			</Button>
		);
	}
	if (props.isOwner) {
		CTATitle = (
			<>
				<Button component={RouterLink} to={'/my/cards/' + props.cardID} variant='outlined' className={classes.button}>
					Edit
				</Button>
				<div className={classes.space}></div>
				<Button variant='outlined' color='primary' className={classes.button} onClick={props.share}>
					Share
				</Button>
			</>
		);
	}

	return <div className={classes.root}>{CTATitle}</div>;
};
export default FullCardActions;
