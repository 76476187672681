export default {
	apiKey: 'AIzaSyACheBGSnBriqCKCasTOurReUWFA7eskP4',
	authDomain: 'magnes-connect-live.firebaseapp.com',
	databaseURL: 'https://magnes-connect-live.firebaseio.com',
	projectId: 'magnes-connect-live',
	storageBucket: 'magnes-connect-live.appspot.com',
	messagingSenderId: '939373249401',
	appId: '1:939373249401:web:00fc56636d4848c06065a8',
	measurementId: 'G-T0G0N23D3C',
};
