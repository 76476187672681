import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { Link as RouterLink } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
	bottomOverride: {
		bottom: theme.spacing(10),
	},
}));

const Snacks = (props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('md'));
	const classes = useStyles();
	let position = {
		vertical: 'bottom',
		horizontal: 'center',
	};
	let content = null;
	let action = null;
	let color = {};
	if (props.Snack.Action.type === 'link') {
		if (!props.Snack.alert) {
			color = { color: 'primary' };
		} else {
			color = { color: 'secondary' };
		}
		action = (
			<Button {...color} onClick={props.onSnackClose} component={RouterLink} to={props.Snack.Action.to}>
				{props.Snack.Action.text}
			</Button>
		);
	}
	if (matches === 'fails') {
		position = {
			vertical: 'top',
			horizontal: 'right',
		};
	}

	if (props.Snack.alert) {
		content = (
			<MuiAlert elevation={10} onClose={props.onSnackClose} severity={props.Snack.severity} variant='filled' action={action}>
				{props.Snack.message}
			</MuiAlert>
		);
	} else {
		content = <SnackbarContent elevation={10} message={props.Snack.message} action={action} />;
	}
	return (
		<Snackbar
			classes={{ anchorOriginBottomCenter: classes.bottomOverride }}
			anchorOrigin={position}
			open={props.Snack.open}
			autoHideDuration={6000}
			onClose={props.onSnackClose}
		>
			{content}
		</Snackbar>
	);
};

export default Snacks;
