import React, { Component } from 'react';
import { connect } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

class QuickConnect extends Component {
	render() {
		let Cards = null;
		if (this.props.cards) {
			Cards = Object.keys(this.props.cards).map((id) => {
				let Card = this.props.cards[id];
				return (
					<MenuItem key={id} value={Card.cardHandle}>
						{Card.cardHandle}
					</MenuItem>
				);
			});
		}

		return (
			<Dialog open={this.props.show} onClose={this.props.modalClosed}>
				<DialogTitle>Share Your Card</DialogTitle>
				{this.props.authInfo !== null ? (
					<DialogContent>
						<Typography>Selected</Typography>
						<Select>{Cards}</Select>

						<Typography>Magnes Share</Typography>

						<List component='nav'>
							<ListItem button>
								<ListItemText primary='Exchange Cards' />
							</ListItem>
							<ListItem button>
								<ListItemText primary='Send Card' />
							</ListItem>
							<ListItem button>
								<ListItemText primary='Collect Card' />
							</ListItem>
						</List>
						<Typography>External Share via</Typography>
						<List component='nav'>
							<ListItem button>
								<ListItemText primary='Text' />
							</ListItem>
							<ListItem button>
								<ListItemText primary='Email' />
							</ListItem>
							<ListItem button>
								<ListItemText primary='Whatsapp' />
							</ListItem>
							<ListItem button>
								<ListItemText primary='Others' />
							</ListItem>
						</List>
					</DialogContent>
				) : (
					//TODO: Change sign up to use link to close and direct to sign up
					<DialogContent>
						<DialogContentText>Please sign in to start sharing your card</DialogContentText>
					</DialogContent>
				)}
			</Dialog>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		cards: state.user.Cards,
		authInfo: state.auth.authInfo,
		authToken: state.auth.authToken,
	};
};

export default connect(mapStateToProps)(QuickConnect);
