import axios from '../bsw/axios/axios';

export const setAuthHeader = (token) => {
	if (token) {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
	} else {
		delete axios.defaults.headers.common['Authorization'];
	}
};
export const resParseGeneral = (res) => {
	return res.data;
};

export const reqTest = (imageForm) => {
	return axios.post('/test', imageForm);
};

export const reqMyUserProfile = () => {
	return axios.get('/user');
};
export const reqUserProfile = (userHandle) => {
	return axios.get(`/user/${userHandle}`);
};
export const reqUserProfileByID = (userID) => {
	return axios.get(`/user_byID/${userID}`);
};
export const reqSaveUser = (card) => {
	return axios.post('/user/save', card);
};
export const reqSaveUserImage = (imageForm) => {
	return axios.post('/user/image', imageForm);
};
export const reqUserHandleSubmit = (reqHandle) => {
	return axios.post('/user/handle/submit', { userHandle: reqHandle });
};
export const reqUserHandleCheck = (reqHandle) => {
	return axios.post('/user/handle/check', { userHandle: reqHandle });
};

export const reqCard = (userHandle, cardHandle) => {
	return axios.get(`/card/${userHandle}/${cardHandle}`);
};
export const reqCardByID = (cardID) => {
	return axios.get(`/card_byID/${cardID}`);
};
export const reqSaveCard = (card) => {
	return axios.post('/card/save', card);
};
export const reqSaveCardImage = (imageForm) => {
	return axios.post('/card/image', imageForm);
};
export const reqAccessCard = (access) => {
	return axios.post('/card/access', access);
};
export const reqDeleteCard = (cardID) => {
	return axios.delete(`/card/${cardID}`);
};

export const reqCollection = () => {
	return axios.get('/collection');
};
export const reqCollectionAdd = (add) => {
	return axios.post('/collection/add', add);
};
export const reqCollectionRemove = (remove) => {
	return axios.post('/collection/remove', remove);
};
export const reqCollectionEdit = (edits) => {
	return axios.post('/collection/edit', edits);
};

export const reqExplore = (withSuggestions) => {
	return axios.post('/explore', { withSuggestions });
};
export const reqExploreSearch = (input, filter, cursor = null) => {
	let search = {};
	if (cursor) {
		search = { searchText: input, searchType: filter, searchCursor: cursor };
	} else {
		search = { searchText: input, searchType: filter };
	}
	return axios.post('/explore/search', search);
};
export const reqExploreSuggestions = () => {
	const promise1 = new Promise((resolve) => {
		resolve([
			{ type: 'hashtags', value: 'test' },
			{ type: 'category', value: 'test1' },
			{ type: 'location', value: 'test2' },
			{ type: 'hashtags', value: 'test2' },
			{ type: 'category', value: 'test13' },
			{ type: 'location', value: 'test23' },
		]);
	});
	return promise1;
	//return axios.get('/explore/search');
};
