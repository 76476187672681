import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	link: {
		width: '100%',
		textAlign: 'center',
	},
}));

const DisplayLinks = (props) => {
	const classes = useStyles();
	let element = null;

	if (props.details.length !== 0) {
		element = props.details.map((detail) => {
			return (
				<Button key={detail.display} className={classes.link} href={`http://${detail.value}`} target='_blank' rel='noopener noreferrer'>
					{detail.display}
				</Button>
			);
		});
	}
	return element;
};

export default DisplayLinks;
