import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import LoadingPage from '../../components/UI/LoadingPage/LoadingPage';

import MiniCard from '../../components/Cards/MiniCard/MiniCard';
import DeleteCardDialog from '../../components/Edits/DeleteCardDiaglog';

import { MagnesShare } from '../../bsw/utility';
import { reqDeleteCard, resParseGeneral } from '../../database/databaseAPI';
//import * as actionTypes from '../../store/actions/actionTypes';
import * as actions from '../../store/actions/index';

const styles = (theme) => ({
	addSection: { textAlign: 'center' },
	noCard: {
		margin: theme.spacing(2),
		padding: theme.spacing(1),
		color: theme.palette.primary.contrastText,
		background: theme.palette.secondary.light,
	},
	addCard: {
		color: theme.palette.primary.main,
		padding: 'auto',
	},
	root: {
		//marginTop: theme.spacing(1),
		maxWidth: '30rem',
		margin: '0 auto',
	},
});

class YourProfile extends Component {
	state = {
		Delete: {
			open: false,
			title: null,
			cardHandle: null,
			cardID: null,
		},
	};
	handlerShare = (cardHandle, title) => {
		const { userHandle } = this.props;
		MagnesShare(title, userHandle, cardHandle);
	};

	handlerDelete = (cardID, title, cardHandle) => {
		this.setState({
			Delete: {
				open: true,
				title: title,
				cardHandle: cardHandle,
				cardID: cardID,
			},
		});
	};
	handleCancel = () => {
		this.setState((prevState, props) => ({
			Delete: {
				...prevState.Delete,
				open: false,
			},
		}));
	};
	handleContinue = () => {
		reqDeleteCard(this.state.Delete.cardID).then((response) => {
			const card = resParseGeneral(response);
			let Cards = { ...this.props.Cards };
			delete Cards[card.cardID];
			this.props.onDeleteCard(Cards);
		});
		this.setState((prevState, props) => ({
			Delete: {
				...prevState.Delete,
				open: false,
			},
		}));
	};

	render() {
		let displayCards = null;
		const { classes, Cards, userHandle, loading } = this.props;
		if (!loading) {
			const cardList = Object.keys(Cards).sort((el1, el2) => {
				let res = 0;
				const card1 = Cards[el1].title.toLowerCase();
				const card2 = Cards[el2].title.toLowerCase();
				if (card1 > card2) {
					res = 1;
				} else if (card1 < card2) {
					res = -1;
				}
				return res;
			});
			if (cardList.length !== 0) {
				displayCards = cardList.map((cardID, index) => {
					return (
						<MiniCard
							key={cardID}
							cardID={cardID}
							cardHandle={Cards[cardID].cardHandle}
							userHandle={userHandle}
							title={Cards[cardID].title}
							description={Cards[cardID].description}
							imageUrl={Cards[cardID].imageUrl}
							isCardOwner={true}
							share={this.handlerShare}
							delete={this.handlerDelete}
						/>
					);
				});
			} else {
				displayCards = (
					<Paper className={classes.noCard}>
						<Typography variant='subtitle1'>No cards as yet. Start creating your own cards.</Typography>
					</Paper>
				);
			}
		}

		return loading ? (
			<LoadingPage />
		) : (
			<>
				<div className={classes.root}>
					{displayCards}
					<div className={classes.addSection}>
						<Fab className={classes.addCard} aria-label='add' component={RouterLink} to='/my/cards/new'>
							<AddIcon />
						</Fab>
					</div>
				</div>
				<DeleteCardDialog
					open={this.state.Delete.open}
					title={this.state.Delete.title}
					cardHandle={this.state.Delete.cardHandle}
					cardID={this.state.Delete.cardID}
					onContinue={this.handleContinue}
					onCancel={this.handleCancel}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		Cards: state.user.Cards,
		userHandle: state.user.userHandle,
		loading: state.UI.user_loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDeleteCard: (Cards) => dispatch(actions.userInfoSetCards(Cards)),
	};
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(YourProfile));
