import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';

import background from '../../../assets/cardGroupBackground.jpg';

const useStyles = makeStyles((theme) => ({
	CardGroup: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '0.125rem',
		overflow: 'hidden',
	},
	CardGroupDetails: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'center',
		left: 0,
		padding: theme.spacing(1),
		right: 0,
		bottom: 0,
		background: 'rgba(255, 255, 255, 0.8)',
		color: theme.palette.common.black,
		overflow: 'hidden',
	},
	CardGroupImages: {
		position: 'absolute',
		top: 0,
		height: '100%',
	},
	CardBackgroundImg: {
		width: '100%',
		padding: 0,
		margin: 0,
		display: 'block',
	},
	CardGrid_A: {
		height: '100%',
	},
	CardGrid_B: {
		width: '100%',
	},
	CardImg_A: {
		height: '100%',
		padding: 0,
		margin: 0,
		display: 'block',
	},
	CardImg_B: {
		width: '100%',
		padding: 0,
		margin: 0,
		display: 'block',
	},
	CardBlank: {},
}));

const CardGroup = (props) => {
	const classes = useStyles();
	const maxGroupMems = 4;

	const cardGroup = [
		{
			size: [12],
			classImg: classes.CardImg_B,
			classGrid: classes.CardGrid_B,
		},
		{
			size: [6, 6],
			classImg: classes.CardImg_A,
			classGrid: classes.CardGrid_A,
		},
		{
			size: [6, 6, 12],
			classImg: classes.CardImg_B,
			classGrid: classes.CardGrid_B,
		},
		{
			size: [6, 6, 6, 6],
			classImg: classes.CardImg_B,
			classGrid: classes.CardGrid_B,
		},
	];
	const num = props.groupCards.length >= maxGroupMems ? maxGroupMems : props.groupCards.length;
	let Cards = props.groupCards.map((Card, idx) => {
		if (idx > maxGroupMems) {
			return null;
		}
		return (
			<Grid key={idx} item xs={cardGroup[num - 1].size[idx]} className={cardGroup[num - 1].classGrid}>
				<img alt='' src={Card.imageUrl} className={cardGroup[num - 1].classImg} />
			</Grid>
		);
	});

	return (
		<ButtonBase className={classes.CardGroup} component={RouterLink} to={'/my/collection/' + props.groupID}>
			<img className={classes.CardBackgroundImg} alt='' src={background} />
			<Grid className={classes.CardGroupImages} container>
				{Cards}
			</Grid>
			<div className={classes.CardGroupDetails}>
				<Typography noWrap variant='subtitle2'>
					{props.groupName}
				</Typography>
			</div>
		</ButtonBase>
	);
};
export default CardGroup;
