import React from 'react';
import reactStringReplace from 'react-string-replace';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import Actions from './FullCardActions';
import Options from './FullCardHeaderOptions';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	top: {
		display: 'flex',
		alignItems: 'center',
	},
	imageFrame: {
		width: '40%',
	},
	image: {
		width: '100%',
		display: 'block',
	},
	main: {
		width: '60%',
		margin: theme.spacing(0, 0, 0, 1),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	handle: {
		color: theme.palette.primary.contrastText,
	},
	tags: {
		textDecoration: 'none',
	},
	title: {
		//margin: theme.spacing(1),
		//width: '100%',
		flexGrow: 1,
		textAlign: 'center',
	},
	mainText: {
		width: '100%',
		textOverflow: 'ellipsis',
		textAlign: 'center',
	},
	category: {
		fontStyle: 'italic',
	},
	divider: {
		margin: theme.spacing(1, 0),
	},
	divider1: {
		margin: 'auto',
		width: '50%',
		padding: theme.spacing(0, 0, 1, 0),
	},
}));

const FullCardHeader = (props) => {
	const classes = useStyles();
	const description = reactStringReplace(props.description, /#(\w+)/g, (x, i) => {
		return (
			<RouterLink key={i} to={'/?hashtags=' + x} className={classes.tags}>
				<Typography color='primary' component='span' variant='body1'>
					#{x}
				</Typography>
			</RouterLink>
		);
	});
	return (
		<>
			<div className={classes.top}>
				<Typography className={classes.title} variant='h5'>
					{props.title}
				</Typography>
				<Options
					cardID={props.cardID}
					collLoadState={props.collLoadState}
					collDetails={props.collDetails}
					groupNames={props.groupNames}
					updateCollection={props.updateCollection}
					updateAccess={props.updateAccess}
					Access={props.Access}
					isOwner={props.isOwner}
					add={props.add}
					remove={props.remove}
					share={props.share}
				/>
			</div>
			<Divider variant='middle' className={classes.divider} />
			<div className={classes.root}>
				<div className={classes.imageFrame}>
					<img className={classes.image} alt='' src={props.imageUrl} />
				</div>
				<div className={classes.main}>
					<Actions
						add={props.add}
						remove={props.remove}
						share={props.share}
						cardID={props.cardID}
						inCollection={props.inCollection}
						isRequested={props.isRequested}
						isDeleted={props.isDeleted}
						isOwner={props.isOwner}
					/>
					<RouterLink to={'/user/' + props.userHandle} className={classes.handle}>
						<Typography variant='h6'>@{props.userHandle}</Typography>
					</RouterLink>
					<Typography variant='h6'>~{props.cardHandle}</Typography>
				</div>
			</div>
			<Typography variant='body1' className={classes.category}>
				{props.category}
			</Typography>
			<Typography variant='body1'>{description}</Typography>
			<Divider variant='middle' className={classes.divider} />
		</>
	);
};

export default FullCardHeader;
