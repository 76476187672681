import { Share } from './share/Share';

export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};
export const updateArray = (oldArray, updateElement, postion = -1) => {
	const newArray = [...oldArray];
	if (postion < 0 || postion >= oldArray.length) {
		newArray.push(updateElement);
	} else {
		newArray[postion] = updateElement;
	}

	return newArray;
};

export const checkValidity = (value, rules) => {
	let isValid = true;
	let message = '';
	if (!rules) {
		return { isValid, message };
	}
	if (rules.nullAllowed && value === null) {
		return { isValid, message };
	}
	if (!rules.required && isValid && value === '') {
		return { isValid, message };
	}
	if (rules.required && isValid) {
		isValid = value.trim() !== '' && isValid;
		if (!isValid) message = 'Cannot be empty';
	}

	if (rules.minLength && isValid && value.length !== 0) {
		isValid = value.length >= rules.minLength;
		if (!isValid) {
			if (rules.maxLength) {
				message = 'Length must be between ' + rules.maxLength + ' & ' + rules.minLength;
			} else {
				message = 'Length must be longer than ' + rules.minLength;
			}
		}
	}

	if (rules.maxLength && isValid) {
		isValid = value.length <= rules.maxLength;
		if (!isValid) {
			if (rules.minLength) {
				message = 'Length must be between ' + rules.maxLength + ' & ' + rules.minLength;
			} else {
				message = 'Length must be shorter than ' + rules.maxLength;
			}
		}
	}

	if (rules.isEmail && isValid) {
		const pattern = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;
		isValid = pattern.test(value);
		if (!isValid) message = 'Not a valid email address';
	}

	if (rules.isNumeric && isValid) {
		const pattern = /^\d+$/;
		isValid = pattern.test(value);
		if (!isValid) message = 'Must be a number';
	}

	if (rules.isUserHandle && isValid) {
		const pattern = /^[A-Za-z0-9._]{1,30}$/;
		isValid = pattern.test(value);
		if (!isValid) message = "Can contain only alphanumeric characters or '.' or '_' ";
	}

	if (rules.isPhoneNumber && isValid) {
		const pattern = /^[0-9._+-]{1,30}$/;
		isValid = pattern.test(value);
		if (!isValid) message = "Can contain only alphanumeric characters or '.' or '_' ";
	}

	if (rules.isUsername && isValid) {
		isValid = validUsernameCheck(value);
		if (!isValid) message = 'Must be a valid username ToDo';
	}

	if (rules.hashTagLimit && isValid) {
		const hashTags = value.match(/#(\w+)/g);
		isValid = hashTags ? value.match(/#(\w+)/g).length <= rules.hashTagLimit : true;
		if (!isValid) message = `Can only use a maximum ${rules.hashTagLimit} hashtags`;
	}

	if (rules.isWebsite && isValid) {
		//const pattern = /
		//isValid = pattern.test(value);
		//if (!isValid) message = "Can contain only alphanumeric characters or '.' or '_' ";
	}

	return { isValid, message };
};

const validUsernameCheck = (username) => {
	const regex = /^[a-z0-9._]{1,30}$/;
	const regex1 = /^[.]/;
	const regex2 = /[.]$/;
	const regex3 = /[.]{2}/;
	//Validate TextBox value against the Regex.
	return regex.test(username) && !regex1.test(username) && !regex2.test(username) && !regex3.test(username);
};

export const sortCollection = (collection) => {
	var sortedColl = [];
	var group = [];
	var groupedCollection = [];
	var groupNames = [];
	var currentGroup = null;
	let cardCount;
	let groupCount = 1;

	let Properties = {};
	if (collection['Properties']) {
		Properties = collection['Properties'];
	}
	sortedColl = Object.keys(collection)
		.filter((id) => id !== 'Properties')
		.map((id) => {
			let card = collection[id];
			return {
				id,
				group: null,
				...card,
			};
		});

	sortedColl.sort(function(card1, card2) {
		let res = 0;

		if (card1.group > card2.group || (card1.group === null && card2.group !== null)) {
			res = 1;
		} else if (card1.group < card2.group || (card2.group === null && card1.group !== null)) {
			res = -1;
		} else {
			if (card1.title > card2.title) {
				res = 1;
			} else if (card1.title < card2.title) {
				res = -1;
			}
		}
		return res;
	});
	if (sortedColl.length > 0) {
		currentGroup = sortedColl[0].group;
		for (cardCount = 0; cardCount < sortedColl.length; cardCount++) {
			if (currentGroup === sortedColl[cardCount].group) {
				group.push(sortedColl[cardCount]);
			} else {
				groupedCollection.push({ GroupID: groupCount, GroupName: currentGroup, Cards: group });
				groupNames.push(currentGroup);
				currentGroup = sortedColl[cardCount].group;
				group = [];
				groupCount++;
				group.push(sortedColl[cardCount]);
			}
		}
		if (currentGroup === null) {
			groupCount = 0;
		} else {
			groupNames.push(currentGroup);
		}
		groupedCollection.push({ GroupID: groupCount, GroupName: currentGroup, Cards: group });
	}
	return { groupedCollection, groupNames, Properties };
};

export const MagnesShare = (title, userHandle, cardHandle) => {
	const shareUrl = `https://demo.magnesconnect.com/user/${userHandle}/${cardHandle}`;
	const shareTitle = title;
	const shareText = `${title} @${userHandle}~${cardHandle} has been shared with you`;
	const shareData = {
		url: shareUrl,
		title: shareTitle,
		text: shareText,
	};
	Share(shareData);
};
