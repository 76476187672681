import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	root: { textAlign: 'center', margin: '2rem' },
});
class DemoWall extends Component {
	state = {
		password: '',
		error: '',
	};
	inputChangedHandler = (event) => {
		this.setState({ password: event.target.value, error: '' });
	};
	accessCheck = () => {
		if (this.state.password === 'M@gn3s') {
			this.props.grantAccess();
		} else {
			this.setState({ password: '', error: 'Invalid Password' });
		}
	};
	enterPressed = (event) => {
		let code = event.keyCode || event.which;
		if (code === 13) {
			//13 is the enter keycode
			this.accessCheck();
		}
	};
	render() {
		const { classes } = this.props;
		return (
			<>
				<div className={classes.root}>
					<Typography variant='h5'>Enter Demo Access Code</Typography>
					<TextField
						onChange={this.inputChangedHandler}
						value={this.state.password}
						onKeyPress={this.enterPressed}
						type='password'
						autoComplete='current-password'
						name={'password'}
						error={this.state.error !== ''}
						helperText={this.state.error}
					/>
					<Button color='primary' onClick={this.accessCheck}>
						Enter
					</Button>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		authInfo: state.auth.authInfo,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		grantAccess: () => dispatch({ type: 'DEMO_GRANTED' }),
	};
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DemoWall));
