import * as actionTypes from './actionTypes';
import { reqMyUserProfile, resParseGeneral, reqUserHandleSubmit } from '../../database/databaseAPI';

export const userInfoSetCards = (Cards) => {
	return {
		type: actionTypes.USERINFO_SET_CARDS,
		Cards: Cards,
	};
};

export const userInfoSetUser = (userInfo) => {
	return {
		type: actionTypes.SET_USER,
		userHandle: userInfo.userHandle,
		Cards: userInfo.Cards,
		displayName: userInfo.displayName,
		photoURL: userInfo.photoURL,
		recentSearches: userInfo.recentSearches,
	};
};
export const userInfoSetRecommendations = (Recommendations) => {
	return {
		type: actionTypes.USERINFO_SET_RECOMMENDATIONS,
		Recommendations: Recommendations,
	};
};
export const userInfoSetUserHandle = (userHandle) => {
	return {
		type: actionTypes.SET_USER_HANDLE,
		userHandle: userHandle,
	};
};
export const userHandleStatusUpdate = (msg, status) => {
	return {
		type: actionTypes.USERINFO_SET_USERHANDLE_REQ_STATUS,
		userHandleMsg: msg,
		userHandleReqSts: status,
	};
};

export const userInfoGetUser = (authDone) => {
	return (dispatch) => {
		if (authDone) {
			dispatch({ type: actionTypes.GET_USER });
			reqMyUserProfile()
				.then((response) => {
					dispatch(userInfoSetUser(resParseGeneral(response)));
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status === 404) {
							// Request made and server responded
							setTimeout(() => {
								reqMyUserProfile()
									.then((response) => {
										dispatch(userInfoSetUser(resParseGeneral(response)));
									})
									.catch((error) => {
										if (error.response) {
											// Request made and server responded
											console.log(error.response.data);
											console.log(error.response.status);
											console.log(error.response.headers);
										} else if (error.request) {
											// The request was made but no response was received
											console.log(error.request);
										} else {
											// Something happened in setting up the request that triggered an Error
											console.log('Error', error.message);
										}
										dispatch({ type: actionTypes.SET_USER });
									});
							}, 2000);
						}
					} else if (error.request) {
						// The request was made but no response was received
						console.log(error.request);
						dispatch({ type: actionTypes.SET_USER });
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
						dispatch({ type: actionTypes.SET_USER });
					}
				});
		} else {
			dispatch({ type: actionTypes.SET_USER });
		}
	};
	//TODO: Implement catch for then. Develop strategy to handle all errors.
};

export const editUserHandle = (userHandle) => {
	return (dispatch) => {
		dispatch(userHandleStatusUpdate(`'${userHandle.toLowerCase()}' is being requested`, 'LOADING'));
		reqUserHandleSubmit(userHandle)
			.then((res) => {
				if (res.data.success) {
					dispatch(userHandleStatusUpdate(res.data.message, 'PASS'));
					dispatch(userInfoSetUserHandle(res.data.userHandle));
				} else {
					dispatch(userHandleStatusUpdate(res.data.message, 'FAIL'));
				}
			})
			.catch((err) => {
				dispatch(userHandleStatusUpdate(err.response.data.message, 'FAIL'));
			});
	};
};

export const userInfoSavedUser = (userInfo) => {
	return {
		type: actionTypes.SAVED_USER,
		...userInfo,
	};
};
