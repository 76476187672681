import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import EditAddress from './EditAddress';

import { generalOptions, addressOptions } from '../../appcfg/appConstants';

const useStyles = makeStyles((theme) => ({
	textfields: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

const EditGeneral = (props) => {
	const [addressOpen, setAddressOpen] = useState(false);

	const classes = useStyles();

	const generalState = props.generalState;
	let addressValue = '';
	const addressKeys = Object.keys(addressOptions);
	addressKeys.forEach((key) => {
		if (generalState['Address'][key]) {
			addressValue = addressValue + generalState['Address'][key] + ' ';
		}
	});

	return (
		<>
			<TextField
				label={generalOptions['website'].display}
				className={classes.textfields}
				name={'website'}
				value={generalState['website']}
				onChange={props.handlerTextChange}
				error={props.errors['website'] !== '' && props.errors['website'] !== null && props.errors['website'] !== undefined}
				helperText={props.errors['website'] !== '' && props.errors['website'] ? props.errors['website'] : generalOptions['website'].tipText}
			/>
			<TextField
				label={generalOptions['email'].display}
				className={classes.textfields}
				name={'email'}
				value={generalState['email']}
				onChange={props.handlerTextChange}
				error={props.errors['email'] !== '' && props.errors['email'] !== null && props.errors['email'] !== undefined}
				helperText={props.errors['email'] !== '' && props.errors['email'] ? props.errors['email'] : generalOptions['email'].tipText}
			/>
			<TextField
				label={generalOptions['phone'].display}
				className={classes.textfields}
				name={'phone'}
				value={generalState['phone']}
				onChange={props.handlerTextChange}
				error={props.errors['phone'] !== '' && props.errors['phone'] !== null && props.errors['phone'] !== undefined}
				helperText={props.errors['phone'] !== '' && props.errors['phone'] ? props.errors['phone'] : generalOptions['phone'].tipText}
			/>
			<TextField
				label={generalOptions['Address'].display}
				className={classes.textfields}
				name={'Address'}
				value={addressValue}
				onChange={props.handlerSelect}
				onClick={() => {
					setAddressOpen(true);
				}}
				InputProps={{
					readOnly: true,
				}}
				error={props.errors['Address'].AddressEmpty} //TODO: Remove dead code related to this
				helperText={props.errors['Address'].AddressEmpty ? 'No address fields' : ''}
			/>
			<EditAddress
				open={addressOpen}
				handleClose={() => {
					setAddressOpen(false);
				}}
				handlerTextChange={props.handlerAddTextChange}
				deleteField={props.deleteAddField}
				addressState={generalState['Address']}
				errors={props.errors.Address}
			/>
		</>
	);
};
export default EditGeneral;
