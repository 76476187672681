import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { reqExplore, resParseGeneral, /* reqExploreSuggestions, */ reqExploreSearch } from '../../database/databaseAPI';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as actionTypes from '../../store/actions/actionTypes';

import Popper from '@material-ui/core/Popper';

import * as backgrounds from '../../assets/explorebackground/index';

import SuggestionResult from '../../components/Explore/SuggestionResult';
import ExploreChip from '../../components/Explore/SuggestionChip';
import SearchType from '../../components/Explore/SearchType';
//import SearchSettings from '../../components/Explore/SearchSettings';
import ExploreCards from '../../components/Explore/ExploreCards';

const styles = (theme) => ({
	root: { maxWidth: '80rem', margin: '0 auto' },
	search: {
		width: '80%',
		margin: 'auto',
	},
	searchField: {
		width: '100%',
		marginBottom: theme.spacing(3),
	},
	searchList: {
		display: 'flex',
		flexWrap: 'wrap',
		marginBottom: theme.spacing(3),
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},

	loading: {
		textAlign: 'center',
		width: '100%',
	},

	background_base: {
		color: 'white',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		width: '100%',
		height: '20vh',
		marginBottom: theme.spacing(5),
		position: 'relative',
		'& > *': {
			position: 'absolute',
			bottom: '0',
			left: '0.25rem',
			fontSize: '1.5rem',
		},
	},
	background_Architect: {
		backgroundImage: `url(${backgrounds.architect})`,
	},
	background_NailTechnician: {
		color: '#3a3531',
		backgroundImage: `url(${backgrounds.nailtechnician})`,
	},
	background_Programmer: {
		backgroundImage: `url(${backgrounds.programmer})`,
	},
	background_WeddingPlanner: {
		color: theme.palette.primary.main,
		backgroundImage: `url(${backgrounds.wedding})`,
	},
	background_PartyPlanner: {
		color: '#a3f1e2',
		backgroundImage: `url(${backgrounds.partyplanner})`,
	},
	background_Writer: {
		color: '#d1b789',
		backgroundImage: `url(${backgrounds.writer})`,
	},
	background_Mechanic: {
		backgroundImage: `url(${backgrounds.mechanic})`,
	},
	background_Photographer: {
		backgroundImage: `url(${backgrounds.photographer})`,
	},
	background_Gardener: {
		backgroundImage: `url(${backgrounds.gardener})`,
	},
	background_Baker: {
		color: '#482f2b',
		backgroundImage: `url(${backgrounds.baker})`,
	},
});

class Explore extends Component {
	_isMounted = false;
	_currentState = 'INIT';
	state = {
		Cards: [],
		suggestions: [],
		recents: [],
		bgRandom: Math.floor(Math.random() * 10),
		searchActive: false,
		searchAutoCmpltValue: null,
		searchText: '',
		searchType: 'all',
		searchStarted: false,
		searchInProgress: false,
		searchSuggestions: [],
		loading: true,
		hasMore: true,
	};
	componentDidMount() {
		this._isMounted = true;
		const query = queryString.parse(this.props.location.search);
		if (query.hashtags) {
			this.exploreStateManager('searchRequested', { value: query.hashtags, type: 'hashtags' });
		} else {
			this.exploreStateManager('exploreRequested', {});
		}
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	exploreStateManager = (eventName, eventParams) => {
		if (this._isMounted) {
			switch (eventName) {
				case 'resetSearch':
					this.setState({
						Cards: [],
					});
					this._currentState = 'IDLE';
					break;
				case 'exploreRequested':
					this._currentState = 'WAITING_EXPLORE';
					this.setState({
						Cards: [],
					});
					reqExplore(true).then((response) => {
						const search = resParseGeneral(response);
						this.exploreStateManager('exploreLoaded', search);
					});

					break;
				case 'searchRequested':
					this._currentState = 'WAITING_SEARCH';
					const searchValue = eventParams.value ? eventParams.value : this.state.searchText;
					const searchType = eventParams.type && eventParams.type !== 'current' ? eventParams.type : this.state.searchType;
					this.setState({
						searchType: searchType,
						searchText: searchValue,
						loading: true,
						Cards: [],
					});
					reqExploreSearch(searchValue, searchType).then((response) => {
						const search = resParseGeneral(response);
						this.exploreStateManager('searchLoaded', search);
					});

					break;
				case 'exploreLoaded':
					if (this._currentState === 'WAITING_EXPLORE') {
						this.setState({
							Cards: [...eventParams.Cards],
							suggestions: [...eventParams.suggestions],
							loading: false,
							hasMore: true,
						});
						this._currentState = 'LOADED_EXPLORE';
					} else {
						this.setState({
							suggestions: [...eventParams.suggestions],
						});
					}
					break;
				case 'searchLoaded':
					if (this._currentState === 'WAITING_SEARCH') {
						this.setState({
							Cards: [...eventParams.Cards],
							hasMore: eventParams.Cards.length !== 0,
							loading: false,
						});
						this._currentState = 'LOADED_SEARCH';
					}
					break;
				case 'getMoreCardsReq':
					if (this._currentState === 'LOADED_EXPLORE') {
						this.setState({
							loading: true,
						});
						reqExplore(false).then((response) => {
							const search = resParseGeneral(response);
							this.exploreStateManager('exploreMoreLoaded', search);
						});
					}
					if (this._currentState === 'LOADED_SEARCH') {
						this.setState({
							loading: true,
						});
						reqExploreSearch(this.state.searchText, this.state.searchType, this.state.Cards[this.state.Cards.length - 1].cardID).then(
							(response) => {
								const search = resParseGeneral(response);
								this.exploreStateManager('searchMoreLoaded', search);
							}
						);
					}

					break;
				case 'exploreMoreLoaded':
					if (this._currentState === 'LOADED_EXPLORE') {
						this.setState((prevState) => {
							return {
								Cards: [...prevState.Cards, ...eventParams.Cards],
								loading: false,
								hasMore: eventParams.Cards.length !== 0,
							};
						});
					}
					break;
				case 'searchMoreLoaded':
					if (this._currentState === 'LOADED_SEARCH') {
						if (
							eventParams.text === this.state.searchText &&
							eventParams.type === this.state.searchType &&
							eventParams.cursor === this.state.Cards[this.state.Cards.length - 1].cardID
						) {
							this.setState((prevState) => {
								return {
									Cards: [...prevState.Cards, ...eventParams.Cards],
									loading: false,
									hasMore: eventParams.Cards.length !== 0,
								};
							});
						}
					}
					break;

				default:
			}
		}
	};

	handlerSearchChange = (newValue) => {
		//this.exploreStateManager('resetSearch', {});
		this.setState({
			searchAutoCmpltValue: newValue,
		});
		if (newValue) {
			this.exploreStateManager('searchRequested', { value: newValue.value, type: newValue.type });
		}
	};
	handlerInputSearchChange = (newValue) => {
		this.exploreStateManager('resetSearch', {});
		if (newValue !== '') {
			this.updateSuggestions();
			this.setState({
				searchText: newValue,
			});
		} else {
			this.exploreStateManager('exploreRequested', {});
			this.setState({
				searchText: newValue,
				searchSuggestions: [],
				searchStarted: false,
			});
		}
	};
	handleFilterChange = (event) => {
		this.exploreStateManager('resetSearch', {});
		this.setState({
			searchType: event.target.value,
		});
		this.updateSuggestions();
	};
	handleChipClick = (value, type) => {
		this.exploreStateManager('searchRequested', { value, type });
	};

	resetFilter = () => {
		if (this.state.searchText === '') {
			this.setState({
				searchType: 'all',
			});
		}
	};

	searchStarted = () => {
		this.setState({
			searchStarted: true,
		});
	};

	updateSuggestions = () => {
		//TODO: Uncomment when suggestions implemented
		/* clearTimeout(this.handleCheckTimer);

		this.handleCheckTimer = setTimeout(() => {
			if (this.state.searchText !== '') {
				reqExploreSuggestions(this.state.searchText, this.state.searchType).then((response) => {
					this.setState({
						searchSuggestions: response,
					});
				});
			}
		}, 1000); */
	};

	render() {
		const { classes, recentSearches } = this.props;
		const backgroundSettings = [
			{
				phrase: 'Architects',
				style: classes.background_Architect,
			},
			{
				phrase: 'Mechanics',
				style: classes.background_Mechanic,
			},
			{
				phrase: 'Nail Technicians',
				style: classes.background_NailTechnician,
			},
			{
				phrase: 'Programmers',
				style: classes.background_Programmer,
			},
			{
				phrase: 'Wedding Planners',
				style: classes.background_WeddingPlanner,
			},
			{
				phrase: 'Party Planners',
				style: classes.background_PartyPlanner,
			},
			{
				phrase: 'Writers',
				style: classes.background_Writer,
			},
			{
				phrase: 'Photographers',
				style: classes.background_Photographer,
			},
			{
				phrase: 'Gardeners',
				style: classes.background_Gardener,
			},
			{
				phrase: 'Bakers',
				style: classes.background_Baker,
			},
		];

		let ExploreSuggestionItems = null;
		let ExploreRecentItems = null;

		let { Cards, hasMore, suggestions, loading, searchAutoCmpltValue, searchText, searchStarted, searchSuggestions, searchType } = this.state;
		const autoCompleteOptions = [{ type: 'current', value: searchText }, ...searchSuggestions];
		const searchActive = searchText !== '';
		if (suggestions) {
			ExploreSuggestionItems = suggestions.map((suggestion) => {
				return (
					<ExploreChip
						key={suggestion.text + suggestion.type}
						value={suggestion.text}
						type={suggestion.type}
						handleChipClick={this.handleChipClick}
					/>
				);
			});
		}
		if (recentSearches) {
			ExploreRecentItems = recentSearches.slice(0, 4).map((recent) => {
				return <ExploreChip key={recent.text + recent.type} value={recent.text} type={recent.type} handleChipClick={this.handleChipClick} />;
			});
		}
		return (
			<div className={classes.root}>
				<Collapse in={!searchActive}>
					<div className={[classes.background_base, backgroundSettings[this.state.bgRandom].style].join(' ')}>
						<span>who can we help you find?</span>
					</div>
				</Collapse>
				<Collapse in={searchActive} onEntered={this.searchStarted}>
					<SearchType searchType={searchType} handleFilterChange={this.handleFilterChange} />
					{/* <SearchSettings searchType={searchType} handleFilterChange={this.handleFilterChange} /> */}
				</Collapse>
				<div className={classes.search}>
					{/* <TextField
						className={classes.searchField}
						name='search'
						type='text'
						label={'Search for ' + (searchAutoCmpltValue === '' ? backgroundSettings[this.state.bgRandom].phrase : '')}
						value={searchAutoCmpltValue}
						onChange={this.handlerTextChange}
						variant='filled'
					/> */}
					<Autocomplete
						//debug
						className={classes.searchField}
						value={searchAutoCmpltValue}
						onClose={this.resetFilter}
						onChange={(event, newValue) => {
							this.handlerSearchChange(newValue);
						}}
						inputValue={searchText}
						onInputChange={(event, newInputValue) => {
							this.handlerInputSearchChange(newInputValue);
						}}
						options={searchStarted ? autoCompleteOptions : []}
						freeSolo={true}
						fullWidth={true}
						getOptionLabel={(option) => (typeof option === 'string' ? option : option.value)}
						renderInput={(params) => (
							<TextField
								{...params}
								label={'Search for ' + (!searchActive ? backgroundSettings[this.state.bgRandom].phrase : '')}
								variant='filled'
							/>
						)}
						renderOption={(option, { selected }) => <SuggestionResult option={option} selected={selected} />}
						PopperComponent={(params) => (
							<Popper
								{...params}
								modifiers={{
									flip: {
										enabled: false,
									},
								}}
							/>
						)}
					/>
					<Collapse in={!searchActive}>
						{ExploreSuggestionItems.length !== 0 ? (
							<Typography variant='h6' color='primary'>
								Suggestions
							</Typography>
						) : null}
						<div className={classes.searchList}>
							{/* Main Filters */}
							{ExploreSuggestionItems}
						</div>
						{/* Main Categories */}
						{ExploreRecentItems.length !== 0 ? (
							<Typography variant='h6' color='primary'>
								Recent
							</Typography>
						) : null}

						<div className={classes.searchList}>{ExploreRecentItems}</div>
					</Collapse>
				</div>
				<ExploreCards Cards={Cards} fetchData={() => this.exploreStateManager('getMoreCardsReq', {})} hasMore={hasMore} />
				{loading && (
					<div className={classes.loading}>
						<CircularProgress className={classes.loading} />
					</div>
				)}
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		recentSearches: state.user.recentSearches,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onNewSearch: (recentSearch) => dispatch({ type: actionTypes.SAVE_NEW_SEARCH, recentSearch }),
	};
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Explore));
