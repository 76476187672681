import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CategoryIcon from '@material-ui/icons/Category';

const useStyles = makeStyles((theme) => ({
	icon: {
		marginRight: theme.spacing(1),
		color: theme.palette.secondary.dark,
	},
}));

const SuggestionResult = (props) => {
	const classes = useStyles();
	let icon = null;
	let value = props.option.value;
	if (props.option.type === 'current') {
		value = `Search for '${value}'`;
	} else if (props.option.type === 'hashtags') {
		icon = <LocalOfferIcon className={classes.icon} />;
	} else if (props.option.type === 'location') {
		icon = <LocationOnIcon className={classes.icon} />;
	} else if (props.option.type === 'category') {
		icon = <CategoryIcon className={classes.icon} />;
	}
	return (
		<>
			{icon}
			{value}
		</>
	);
};

export default SuggestionResult;
