import * as actionTypes from '../actions/actionTypes';

const initialState = {
	userHandle: null,
	Cards: {},
	recentSearches: [],
	userHandleMsg: null,
	userHandleReqSts: null,
	userLoaded: false,
	photoURL: null,
	displayName: null,
	demoAccess: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_USER:
			return {
				...state,
				userHandle: action.userHandle ? action.userHandle : state.userHandle,
				Cards: action.Cards ? action.Cards : state.Cards,
				photoURL: action.photoURL ? action.photoURL : state.photoURL,
				displayName: action.displayName ? action.displayName : state.displayName,
				recentSearches: action.recentSearches ? action.recentSearches : state.recentSearches,
				userLoaded: true,
			};
		case actionTypes.SAVED_USER:
			return {
				...state,
				userHandle: action.userHandle ? action.userHandle : state.userHandle,
				photoURL: action.photoURL ? action.photoURL : state.photoURL,
				displayName: action.displayName ? action.displayName : state.displayName,
			};
		case actionTypes.SAVE_NEW_SEARCH:
			return {
				...state,
				recentSearches: state.recentSearches.some(
					(search) => search.text === action.recentSearch.text && search.type === action.recentSearch.type
				)
					? state.recentSearches
					: [...state.recentSearches, action.recentSearch],
			};
		case actionTypes.USERINFO_SET_CARDS:
			return {
				...state,
				Cards: action.Cards,
			};
		case actionTypes.USERINFO_SET_RECOMMENDATIONS:
			return {
				...state,
				Recommendations: action.Recommendations,
			};
		case actionTypes.SET_USER_HANDLE:
			return {
				...state,
				userHandle: action.userHandle,
			};

		case actionTypes.USERINFO_SET_USERHANDLE_REQ_STATUS:
			return {
				...state,
				userHandleMsg: action.userHandleMsg,
				userHandleReqSts: action.userHandleReqSts,
			};
		case actionTypes.USERINFO_CLEAR:
			return initialState;
		case 'DEMO_GRANTED':
			return {
				...state,
				demoAccess: true,
			};
		default:
			return state;
	}
};

export default reducer;
