import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CollectionsBookmarkOutlinedIcon from '@material-ui/icons/CollectionsBookmarkOutlined';
import SearchIcon from '@material-ui/icons/Search';
import ViewAgendaOutlinedIcon from '@material-ui/icons/ViewAgendaOutlined';
//import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';

import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
	root: {
		position: 'fixed',
		bottom: 0,
		width: '100%',
		boxShadow: '0 -0.1rem 0.25rem rgba(187, 187, 187, 0.7)',
	},
});
const scrollToTop = () => {
	window.scrollTo(0, 0);
};

const Footer = (props) => {
	const classes = useStyles();
	const [value, setValue] = React.useState(window.location.pathname);
	return (
		<BottomNavigation
			value={value}
			onChange={(event, newValue) => {
				if (newValue !== 'Connect') {
					setValue(newValue);
				}
			}}
			showLabels
			className={classes.root}
		>
			<BottomNavigationAction
				label='Collection'
				value='/my/collection'
				icon={<CollectionsBookmarkOutlinedIcon />}
				component={RouterLink}
				to='/my/collection'
			/>
			<BottomNavigationAction onClick={scrollToTop} label='Explore' value='/' icon={<SearchIcon />} component={RouterLink} to='/' />
			<BottomNavigationAction label='My Cards' value='/my/cards' icon={<ViewAgendaOutlinedIcon />} component={RouterLink} to='/my/cards' />
			{/* <BottomNavigationAction label='Connect' value='Connect' icon={<ShareOutlinedIcon />} onClick={props.connectAction} /> */}
		</BottomNavigation>
	);
};

export default Footer;
