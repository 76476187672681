import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { initializeFirebase } from './bsw/firebase/firebase';
import { firebaseAuthListener, firebaseDecodeToken, firebaseLogout } from './bsw/firebase/firebaseAuth';
import * as actions from './store/actions/index';

import PrivateRoute from './hoc/PrivateRoute';
import Layout from './hoc/Layout/Layout';
import Explore from './containers/Explore/Explore';
import Collection from './containers/Collection/Collection';
import Profile from './containers/Profile/Profile';
import YourProfile from './containers/YourProfile/YourProfile';
import EditProfile from './containers/EditProfile/EditProfile';
import EditCard from './containers/EditCard/EditCard';
import SignUp from './containers/SignUp/SignUp';
import FullCard from './containers/FullCard/FullCard';
import DeletedCard from './containers/DeletedCard/DeletedCard';
import DemoAccessPage from './containers/DemoAccess';
import LoadingPage from './components/UI/LoadingPage/LoadingPage';

initializeFirebase();

class App extends Component {
	componentDidMount() {
		this.authListener = this.authListener.bind(this);
		this.authListener();
	}
	componentWillUnmount() {
		this.fireBaseListener && this.fireBaseListener();
		this.authListener = undefined;
	}
	authListener() {
		this.fireBaseListener = firebaseAuthListener(this.authStateUpdate);
	}

	authStateUpdate = (user) => {
		this.props.onAuthChanged(user);
		if (user) {
			firebaseDecodeToken(user).then((idToken) => {
				this.props.onAuthNewToken(idToken);
				this.props.onNewUser(true);
			});
		} else {
			this.props.onNewUser(false);
		}
	};

	render() {
		let App = <LoadingPage />;

		if (this.props.authDone && this.props.userDone) {
			if (this.props.demoAccess || this.props.authInfo || process.env.NODE_ENV !== 'production') {
				App = (
					<Layout logoutAction={firebaseLogout}>
						<Switch>
							<Route path='/' exact component={Explore} />
							<PrivateRoute path='/my/collection/del/:card' component={DeletedCard} authed={this.props.isAuthenticated} />
							<PrivateRoute path='/my/collection/:group' component={Collection} authed={this.props.isAuthenticated} />
							<PrivateRoute path='/my/collection' component={Collection} authed={this.props.isAuthenticated} />
							<PrivateRoute path='/my/profile' component={EditProfile} authed={this.props.isAuthenticated} />
							<PrivateRoute path='/my/cards/new' component={EditCard} authed={this.props.isAuthenticated} />
							<PrivateRoute path='/my/cards/:cardID' component={EditCard} authed={this.props.isAuthenticated} />
							<PrivateRoute path='/my/cards' component={YourProfile} authed={this.props.isAuthenticated} />
							<Route path='/user/:user/:card' component={FullCard} />
							<Route path='/user/:user' component={Profile} />
							<Route path='/userid/:uid' component={Profile} />
							<Route path='/login' component={SignUp} />
							<Redirect to='/' />
						</Switch>
					</Layout>
				);
			} else {
				App = <DemoAccessPage />;
			}
		}

		return App;
	}
}

const mapStateToProps = (state) => {
	return {
		authInfo: state.auth.authInfo,
		isAuthenticated: state.auth.isAuthenticated,
		authDone: state.auth.authLoaded,
		userDone: state.user.userLoaded,
		demoAccess: state.user.demoAccess,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAuthChanged: (authInfo) => dispatch(actions.authSetUser(authInfo)),
		onAuthNewToken: (authInfo) => dispatch(actions.authSetToken(authInfo)),
		onNewUser: (authSuccess) => dispatch(actions.userInfoGetUser(authSuccess)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
