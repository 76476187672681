import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { socialMediaOptions } from '../../appcfg/appConstants';
const useStyles = makeStyles((theme) => ({
	selects: {
		marginRight: theme.spacing(1),
		flexGrow: 1,
		minWidth: theme.spacing(15),
	},
	textfields: {
		width: '100%',
		[theme.breakpoints.down('xs')]: { marginTop: theme.spacing(1) },
	},
	element: {
		[theme.breakpoints.up('sm')]: { display: 'flex' },
		//marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		alignItems: 'baseline',
	},
	elementactionsXS: {
		display: 'flex',
		[theme.breakpoints.up('sm')]: { display: 'none' },
		'& > *': {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
	},
	elementactions: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: { display: 'none' },
	},
	subelement: {
		flexGrow: 1,
		display: 'flex',
		alignItems: 'flex-start',
	},
}));

const EditSocialMedia = (props) => {
	const classes = useStyles();

	const socialMediaState = props.socialMediaState;
	const socialMediaKeys = Object.keys(socialMediaOptions);
	let filteredSMOs = [...socialMediaKeys];

	let socialMediaUI = [];
	if (socialMediaState) {
		const socialMediaStateList = Object.keys(socialMediaState);
		filteredSMOs = socialMediaKeys.filter((SMO) => socialMediaStateList.indexOf(SMO) < 0);
		socialMediaUI = socialMediaStateList.map((id) => {
			return (
				<div key={id} order={socialMediaState[id].order} className={classes.element}>
					<div className={classes.subelement}>
						<Select className={classes.selects} value={id} onChange={props.handlerSelect} readOnly>
							<MenuItem key={id} value={id}>
								{socialMediaOptions[id].display}
							</MenuItem>
						</Select>
						<div className={classes.elementactionsXS}>
							<IconButton onClick={() => props.deleteField(id)} size='small'>
								<DeleteIcon />
							</IconButton>
							<IconButton
								onClick={() => props.orderChange(socialMediaState[id].order, true)}
								disabled={socialMediaState[id].order === 0}
								size='small'
							>
								<UpIcon />
							</IconButton>
							<IconButton
								onClick={() => props.orderChange(socialMediaState[id].order, false)}
								disabled={socialMediaState[id].order === socialMediaStateList.length - 1}
								size='small'
							>
								<DownIcon />
							</IconButton>
						</div>
					</div>
					<TextField
						className={classes.textfields}
						name={id}
						value={socialMediaState[id].value}
						onChange={props.handlerTextChange}
						error={props.errors[id] !== '' && props.errors[id] !== null && props.errors[id] !== undefined}
						helperText={props.errors[id] !== '' && props.errors[id] ? props.errors[id] : socialMediaOptions[id].tipText}
					/>
					<div className={classes.elementactions}>
						<IconButton onClick={() => props.deleteField(id)}>
							<DeleteIcon />
						</IconButton>
						<IconButton onClick={() => props.orderChange(socialMediaState[id].order, true)} disabled={socialMediaState[id].order === 0}>
							<UpIcon />
						</IconButton>
						<IconButton
							onClick={() => props.orderChange(socialMediaState[id].order, false)}
							disabled={socialMediaState[id].order === socialMediaStateList.length - 1}
						>
							<DownIcon />
						</IconButton>
					</div>
				</div>
			);
		});
	}
	socialMediaUI.sort((el1, el2) => el1.props.order - el2.props.order);

	if (filteredSMOs.length > 0) {
		let emptySMO = filteredSMOs.map((socialMediaKey) => {
			return (
				<MenuItem key={socialMediaKey} value={socialMediaKey}>
					{socialMediaOptions[socialMediaKey].display}
				</MenuItem>
			);
		});
		socialMediaUI.push(
			<div key='new' className={classes.element}>
				<div className={classes.subelement}>
					<Select className={classes.selects} value='' onChange={props.handlerSelect}>
						{emptySMO}
					</Select>
					<div className={classes.elementactionsXS}>
						<IconButton disabled size='small'>
							<DeleteIcon />
						</IconButton>
						<IconButton disabled size='small'>
							<UpIcon />
						</IconButton>
						<IconButton disabled size='small'>
							<DownIcon />
						</IconButton>
					</div>
				</div>
				<TextField
					className={classes.textfields}
					InputProps={{
						readOnly: true,
					}}
				/>
				<div className={classes.elementactions}>
					<IconButton disabled>
						<DeleteIcon />
					</IconButton>
					<IconButton disabled>
						<UpIcon />
					</IconButton>
					<IconButton disabled>
						<DownIcon />
					</IconButton>
				</div>
			</div>
		);
	}

	return socialMediaUI;
};
export default EditSocialMedia;
