import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { MagnesShare } from '../../bsw/utility';

import IconCard from '../../components/Cards/IconCard/IconCard';
import CardGroup from '../../components/Cards/CardGroup/CardGroup';
import LoadingPage from '../../components/UI/LoadingPage/LoadingPage';
import Grid from '@material-ui/core/Grid';

import * as actionTypes from '../../store/actions/actionTypes';
import * as actions from '../../store/actions/index';
import { LOAD_STATE_SUCCESS } from '../../appcfg/constants';

const styles = (theme) => ({
	noCollection: {
		margin: theme.spacing(2),
		padding: theme.spacing(1),
		color: theme.palette.primary.contrastText,
		background: theme.palette.secondary.light,
	},
	root: {
		//marginTop: theme.spacing(1),
		maxWidth: '80rem',
		margin: '0 auto',
	},
	link: {
		color: theme.palette.primary.main,
	},
});

class Collection extends Component {
	componentDidMount() {
		if (this.props.authInfo && this.props.loadState !== LOAD_STATE_SUCCESS) {
			this.props.onGetCollection();
		}
	}

	componentWillUnmount() {
		this.props.onGroupChange(null);
	}
	handlerShare = (title, userHandle, cardHandle) => {
		MagnesShare(title, userHandle, cardHandle);
	};
	render() {
		const { groupedCollection, loadState, classes } = this.props;

		let Favs = <LoadingPage />;
		if (loadState === LOAD_STATE_SUCCESS) {
			if (groupedCollection) {
				if (groupedCollection.length > 0) {
					let Group = undefined;
					if (!(this.props.match.params.group === undefined || this.props.match.params.group === '0')) {
						Group = groupedCollection.find((o) => o.GroupID.toString() === this.props.match.params.group);
					}
					if (this.props.match.params.group === undefined) {
						this.props.onGroupChange(null);
						Favs = groupedCollection.map((Group) => {
							let temp = null;
							if (Group.GroupName != null) {
								temp = (
									<Grid item xs={4} sm={3} md={2} key={Group.GroupID}>
										<CardGroup
											groupName={Group.GroupName}
											groupCards={Group.Cards}
											groupID={Group.GroupID}
											groupSelectAction={this.groupSelectHandler}
										/>
									</Grid>
								);
							} else {
								temp = Group.Cards.map((Card) => {
									return (
										<Grid item xs={4} sm={3} md={2} key={Card.id}>
											<IconCard
												cardID={Card.id}
												title={Card.title}
												imageUrl={Card.imageUrl}
												userHandle={Card.userHandle}
												cardHandle={Card.cardHandle}
												deleted={Card.deleted}
												collLoadState={this.props.loadState}
												collDetails={this.props.collection}
												groupNames={this.props.groupNames}
												updateCollection={this.props.onGetCollection}
												updateAccess={null}
												isOwner={false}
												Access={null}
												add={null}
												remove={null}
												share={() => this.handlerShare(Card.title, Card.userHandle, Card.cardHandle)}
											/>
										</Grid>
									);
								});
							}
							return temp;
						});
					} else if (Group === undefined) {
						Favs = <Redirect to='/my/collection' />;
					} else {
						this.props.onGroupChange(Group.GroupName);
						Favs = Group.Cards.map((Card) => {
							return (
								<Grid item xs={4} sm={3} md={2} key={Card.id}>
									<IconCard
										cardID={Card.id}
										title={Card.title}
										imageUrl={Card.imageUrl}
										userHandle={Card.userHandle}
										cardHandle={Card.cardHandle}
										deleted={Card.deleted}
										collLoadState={this.props.loadState}
										collDetails={this.props.collection}
										groupNames={this.props.groupNames}
										updateCollection={this.props.onGetCollection}
										updateAccess={null}
										isOwner={false}
										Access={null}
										add={null}
										remove={null}
										share={() => this.handlerShare(Card.title, Card.userHandle, Card.cardHandle)}
									/>
								</Grid>
							);
						});
					}
				} else {
					Favs = (
						<Paper className={classes.noCollection}>
							<Typography variant='subtitle1'>
								No cards in collection yet.{' '}
								<RouterLink className={classes.link} to='/'>
									Start exploring
								</RouterLink>{' '}
								and add cards to your collection.
							</Typography>
						</Paper>
					);
				}
			} else {
				Favs = (
					<Paper className={classes.noCollection}>
						<Typography variant='subtitle1'>
							No cards in collection yet.{' '}
							<RouterLink className={classes.link} to='/'>
								Start exploring
							</RouterLink>{' '}
							and add cards to your collection.
						</Typography>
					</Paper>
				);
			}
		}

		return (
			<>
				{groupedCollection.length !== 0 ? (
					<Grid className={classes.root} container>
						{Favs}
					</Grid>
				) : (
					Favs
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		groupedCollection: state.coll.groupedCollection,
		loadState: state.coll.loadState,
		authInfo: state.auth.authInfo,
		collection: state.coll.collection,
		groupNames: state.coll.groupNames,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onGroupChange: (group) => dispatch({ type: actionTypes.COLLECTION_SET_GROUP, group: group }),
		onGetCollection: () => dispatch(actions.collectionGetCollection()),
	};
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Collection));
