import React, { useState, useCallback, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
//const imageMaxSize = 1000000000; // TODO: bytes
//const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
//const acceptedFileTypesArray = acceptedFileTypes.split(',').map((item) => {
//	return item.trim();
//});

const ImageCrop = (props) => {
	const [upImg, setUpImg] = useState();
	const [processState, setProcessState] = useState(0);
	const imgRef = useRef(null);
	const [crop, setCrop] = useState({ width: 500, aspect: 1 / 1 });
	const [imageUrl, setImageUrl] = useState();
	const [imageBlob, setImageBlob] = useState();
	const [loading, setLoading] = useState(false);

	const onSelectFile = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			setLoading(true);
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				setLoading(false);
				setUpImg(reader.result);
			});
			reader.readAsDataURL(e.target.files[0]);
		}
	};
	const onFinish = (event) => {
		if (event) event.preventDefault();
		if (imageBlob && imageUrl) {
			props.onComplete({ Blob: imageBlob, Url: imageUrl });
		}
	};
	const onCancel = (event) => {
		if (event) event.preventDefault();
		props.onCancel();
	};
	const selectImage = () => {
		const fileInput = document.getElementById('imageCropInput');
		fileInput.click();
	};
	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	const makeClientCrop = async (crop) => {
		if (imgRef.current && crop.width && crop.height) {
			createCropPreview(imgRef.current, crop, 'nelwFile.jpg');
		}
	};

	const createCropPreview = async (image, crop, fileName) => {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = 500; //Math.ceil(crop.width * scaleX);
		canvas.height = 500; //Math.ceil(crop.height * scaleY);
		const ctx = canvas.getContext('2d');

		ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, 500, 500);

		return new Promise((resolve, reject) => {
			canvas.toBlob(
				(blob) => {
					if (!blob) {
						reject(new Error('Canvas is empty'));
						return;
					}
					blob.name = fileName;
					window.URL.revokeObjectURL(imageUrl);
					setImageUrl(window.URL.createObjectURL(blob));
					setImageBlob(blob);
				},
				'image/jpg',
				1
			);
		});
	};
	const handleClearToDefault = (event) => {
		if (event) event.preventDefault();
		const fileInput = document.getElementById('imageCropInput');
		setUpImg(null);
		setImageUrl(null);
		setImageBlob(null);
		fileInput.value = '';
	};

	if (props.active) {
		if (processState === 0) {
			selectImage();
			setProcessState(1);
		}
	} else {
		if (processState !== 0) {
			setProcessState(0);
			handleClearToDefault();
		}
	}
	return (
		<div style={{ height: '100%', textAlign: 'center' }}>
			{loading && <CircularProgress />}
			{imageUrl && <img style={{ width: '50%', margin: 'auto' }} alt='Crop preview' src={imageUrl} onClick={selectImage} />}
			<input type='file' accept='image/*' id='imageCropInput' hidden='hidden' onChange={onSelectFile} />
			<div>
				<ReactCrop src={upImg} onImageLoaded={onLoad} crop={crop} onChange={(c) => setCrop(c)} onComplete={makeClientCrop} />
			</div>
			<div>
				<Button onClick={selectImage}>Select</Button>
				<Button onClick={onFinish} disabled={!(imageBlob && imageUrl)}>
					Finish
				</Button>
				<Button onClick={onCancel}>Cancel</Button>
			</div>
		</div>
	);
};

export default ImageCrop;
