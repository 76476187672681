import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

const DeleteCardDialog = (props) => {
	const { onContinue, onCancel, open, cardHandle, title } = props;

	return (
		<Dialog maxWidth={'xs'} keepMounted onClose={onCancel} aria-labelledby='edit-card-dialog' open={open}>
			<DialogTitle id='edit-card-dialog'>Delete ~{cardHandle}</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-slide-description'>
					You are about to delete ~{cardHandle} titled {title}. Do you wish to continue?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onContinue} color='primary'>
					Continue
				</Button>
				<Button onClick={onCancel} color='primary'>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default DeleteCardDialog;
