import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	textField: {
		width: '100%',
	},
}));

const NotesDialog = (props) => {
	const classes = useStyles();
	const { open } = props;
	const [value, setValue] = useState(props.value);
	const [edited, setEdited] = useState(false);
	useEffect(() => {
		if (value !== props.value) {
			setEdited(true);
		} else {
			setEdited(false);
		}
	}, [value, props.value]);

	return (
		<Dialog open={open} onClose={props.handleClose} maxWidth='xs' fullWidth={true}>
			<DialogTitle>Notes</DialogTitle>
			<DialogContent>
				<TextField
					className={classes.textField}
					multiline
					variant='outlined'
					value={value}
					onChange={(event) => {
						setValue(event.target.value);
					}}
				/>
			</DialogContent>
			<DialogActions>
				{edited ? (
					<>
						<Button onClick={props.handleClose} color='primary'>
							Cancel
						</Button>
						<Button
							onClick={() => {
								props.handleSave({ userNotes: value });
							}}
							color='primary'
						>
							Save
						</Button>
					</>
				) : (
					<Button onClick={props.handleClose} color='primary'>
						Close
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default NotesDialog;
