import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Avatar } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import defaultImg from '../../assets/default-img.png';
import { makeStyles } from '@material-ui/core/styles';

import { catergories } from '../../appcfg/appConstants';

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: '50%',
		height: '100%',
		margin: 'auto',
		maxWidth: '10rem',
		//color: theme.palette.getContrastText(theme.palette.secondary.dark),
		//backgroundColor: theme.palette.secondary.dark,
	},
	textfieldsbasic: {
		width: '100%',
	},
}));

const EditBasic = (props) => {
	const classes = useStyles();

	const { avatar, textfieldsbasic } = classes;
	const { title, cardHandle, category, description, imageUrl } = props.values;
	const { etitle, ecardHandle, edescription } = props.errors;
	const avatarIcon = props.newImage ? props.newImage.Url : imageUrl ? imageUrl : defaultImg;
	const [inputValue, setInputValue] = useState('');
	return (
		<>
			<Avatar className={avatar} variant='rounded' src={avatarIcon} onClick={props.imageClick}>
				<CheckBoxOutlineBlankIcon className={avatar} />
			</Avatar>
			<TextField
				name='title'
				type='text'
				label='Card Title'
				required
				placeholder='Title for your card'
				value={title}
				error={etitle !== ''}
				helperText={etitle !== '' ? etitle : 'Title to be displayed for your card'}
				onChange={props.textChange}
				className={textfieldsbasic}
			/>
			<TextField
				name='cardHandle'
				type='text'
				label='Card Handle'
				required
				placeholder='Handle for your card'
				value={cardHandle}
				error={ecardHandle !== ''}
				helperText={ecardHandle !== '' ? ecardHandle : 'Handle to be used for card url'}
				onChange={props.textChange}
				className={textfieldsbasic}
			/>
			{/* <TextField
				name='category'
				type='text'
				label='Card Category'
				placeholder='Category for your card'
				value={category}
				error={ecategory !== ''}
				helperText={ecategory}
				onChange={props.textChange}
				className={textfieldsbasic}
			/> */}
			<Autocomplete
				value={category}
				onChange={props.categoryChange}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				options={catergories.map((x) => x.display)}
				fullWidth={true}
				renderInput={(params) => <TextField {...params} label='Category' />}
			/>
			<TextField
				name='description'
				type='text'
				label='Card Description'
				placeholder='Description for your card'
				multiline
				value={description}
				error={edescription !== ''}
				helperText={edescription !== '' ? edescription : 'Tell us about your self and what you offer'}
				onChange={props.textChange}
			/>
		</>
	);
};
export default EditBasic;
