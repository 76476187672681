import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { reqUserProfile, reqUserProfileByID, resParseGeneral } from '../../database/databaseAPI';
import { LOAD_STATE_INPROGRESS, LOAD_STATE_SUCCESS, LOAD_STATE_FAIL } from '../../appcfg/constants';

import LoadingPage from '../../components/UI/LoadingPage/LoadingPage';
import BrokenLink from '../../components/UI/BrokenLink';

import MiniCard from '../../components/Cards/MiniCard/MiniCard';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

//import * as actionTypes from '../../store/actions/actionTypes';
//import * as actions from '../../store/actions/index';

const styles = (theme) => ({
	noCard: {
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		color: theme.palette.primary.contrastText,
		background: theme.palette.secondary.light,
	},
	addCard: {
		fontSize: theme.typography.h3.fontSize,
		color: theme.palette.secondary.dark,
	},
	avatar: {
		width: theme.spacing(12),
		height: theme.spacing(12),
		background: theme.palette.primary.light,
		fontSize: theme.spacing(8),
	},
	header: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflow: 'hidden',
	},
	cards: {
		//marginTop: theme.spacing(1),
		maxWidth: '30rem',
		margin: '0 auto',
	},
});

class Profile extends Component {
	state = {
		profileUserID: null,
		userHandle: null,
		Cards: {},
		loading: true,
		loadState: LOAD_STATE_INPROGRESS,
	};
	componentDidMount() {
		if (this.props.match.params.user) {
			reqUserProfile(this.props.match.params.user)
				.then((response) => {
					const user = resParseGeneral(response);
					this.setState({
						Cards: user.Cards,
						userHandle: user.userHandle,
						cardID: user.cardID,
						displayName: user.displayName,
						photoURL: user.photoURL,
						profileUserID: user.userID,
						loading: false,
						loadState: LOAD_STATE_SUCCESS,
					});
				})
				.catch((error) => {
					this.setState({
						loadState: LOAD_STATE_FAIL,
					});
				});
		} else if (this.props.match.params.uid) {
			reqUserProfileByID(this.props.match.params.uid)
				.then((response) => {
					const user = resParseGeneral(response);
					this.setState({
						Cards: user.Cards,
						userHandle: user.userHandle,
						cardID: user.cardID,
						displayName: user.displayName,
						photoURL: user.photoURL,
						profileUserID: user.userID,
						loading: false,
						loadState: LOAD_STATE_SUCCESS,
					});
				})
				.catch((error) => {
					this.setState({
						loadState: LOAD_STATE_FAIL,
					});
				});
		}
		window.scrollTo(0, 0);
	}
	render() {
		const { classes } = this.props;
		let displayCards = <LoadingPage />;
		let ProfileElement = <LoadingPage />;
		const { userHandle, Cards, loadState, displayName, photoURL } = this.state;

		if (LOAD_STATE_SUCCESS === loadState) {
			const cardList = Object.keys(Cards).sort((el1, el2) => {
				let res = 0;
				const card1 = Cards[el1].title.toLowerCase();
				const card2 = Cards[el2].title.toLowerCase();
				if (card1 > card2) {
					res = 1;
				} else if (card1 < card2) {
					res = -1;
				}
				return res;
			});
			if (cardList.length !== 0) {
				displayCards = cardList.map((cardID, index) => {
					return (
						<MiniCard
							key={cardID}
							cardID={cardID}
							cardHandle={Cards[cardID].cardHandle}
							userHandle={userHandle}
							title={Cards[cardID].title}
							description={Cards[cardID].description}
							imageUrl={Cards[cardID].imageUrl}
							isCardOwner={false}
							isLastCard={index === cardList.length - 1}
						/>
					);
				});
			} else {
				displayCards = (
					<Paper className={classes.noCard}>
						<Typography variant='subtitle1'>Sorry no Cards available.</Typography>
					</Paper>
				);
			}
			ProfileElement = (
				<>
					<div className={classes.header}>
						<Avatar alt={displayName} src={photoURL ? photoURL : '/'} className={classes.avatar} />
						<Typography noWrap variant='h4'>
							{displayName}
						</Typography>
						{userHandle ? (
							<Typography noWrap variant='h5'>
								@{userHandle}
							</Typography>
						) : null}
					</div>
					<div className={classes.cards}>{displayCards}</div>
				</>
			);
		} else if (LOAD_STATE_FAIL === loadState) {
			ProfileElement = <BrokenLink />;
		}

		return <div className={classes.root}>{ProfileElement}</div>;
	}
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Profile));
