export default {
	palette: {
		primary: {
			//main: '#F5F5F5',
			main: '#06a6fc',
			//contrastText: '#555555',
		},
		secondary: {
			main: '#E5E5E5',
		},
	},
};
