import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import ImageCrop from '../../bsw/ImageCrop/ImageCrop';
const useStyles = makeStyles({
	CardDisplay: {},
});

const EditPic = (props) => {
	const { onCancel, open } = props;
	const classes = useStyles();
	return (
		<Dialog maxWidth={'xs'} keepMounted onClose={onCancel} aria-labelledby='edit-card-dialog' open={open}>
			<DialogTitle id='edit-card-dialog'>Select Picture</DialogTitle>
			<div className={classes.CardDisplay}>
				<ImageCrop active={open} onComplete={props.onComplete} onCancel={onCancel} />
			</div>
		</Dialog>
	);
};
export default EditPic;
