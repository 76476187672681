import * as actionTypes from '../actions/actionTypes';
import { updateObject, sortCollection } from '../../bsw/utility';
import { LOAD_STATE_INIT, LOAD_STATE_INPROGRESS, LOAD_STATE_SUCCESS, LOAD_STATE_FAIL } from '../../appcfg/constants';

const initialState = {
	collection: null,
	groupedCollection: [],
	groupNames: [],
	collectionGroup: null,
	collectionLoaded: false,
	loadState: LOAD_STATE_INIT,
};

const reducer = (state = initialState, action) => {
	let updatedGroup = {
		groupedCollection: [],
		groupNames: [],
	};
	let updatedCollection = null;
	switch (action.type) {
		case actionTypes.GET_COLLECTION:
			return {
				...state,
				loadState: LOAD_STATE_INPROGRESS,
			};
		case actionTypes.SET_COLLECTION:
			updatedGroup = sortCollection(action.collection);
			return {
				...state,
				collection: action.collection,
				groupedCollection: updatedGroup.groupedCollection,
				groupNames: updatedGroup.groupNames,
				loadState: LOAD_STATE_SUCCESS,
			};
		case actionTypes.COLLECTION_SET_GROUP:
			return {
				...state,
				collectionGroup: action.group,
			};
		case actionTypes.ADD_TO_COLLECTION:
			updatedCollection = updateObject(state.collection, action.add);
			updatedGroup = sortCollection(updatedCollection);
			return {
				...state,
				collection: updatedCollection,
				groupedCollection: updatedGroup.groupedCollection,
				groupNames: updatedGroup.groupNames,
			};
		case actionTypes.REMOVE_FROM_COLLECTION:
			updatedCollection = { ...state.collection };
			delete updatedCollection[action.remove];
			updatedGroup = sortCollection(updatedCollection);
			return {
				...state,
				collection: updatedCollection,
				groupedCollection: updatedGroup.groupedCollection,
				groupNames: updatedGroup.groupNames,
			};
		case actionTypes.ERROR_COLLECTION:
			return {
				...state,
				loadState: LOAD_STATE_FAIL,
			};
		default:
			return state;
	}
};

export default reducer;
